import useGameStore from "../../lib/store/GameStore";
import usePersistentStore from "../../lib/store/PersistentStore";
import { BaseDialog } from "./BaseDialog";

function HowToPlayDialog({ modalTitle = "How to Play PokeGrid" }) {
  const [isHelpOpen, setIsHelpOpen] = useGameStore((state) => [
    state.isHelpOpen,
    state.setIsHelpOpen,
  ]);

  const [instructionsDismissed, saveInstructionsDismissed] = usePersistentStore(
    (state) => [state.instructionsDismissed, state.saveInstructionsDismissed]
  );

  const isOpen = isHelpOpen; // || !instructionsDismissed;

  function closeModal() {
    saveInstructionsDismissed(true);
    setIsHelpOpen(false);
  }

  return (
    <>
      <BaseDialog
        isOpen={isOpen}
        closeModal={closeModal}
        modalTitle={modalTitle}
        modalBody={<HowToPlayBody />}
      />
    </>
  );
}

function HowToPlayBody() {
  return (
    <div className="my-2 px-2 pt-2 text-md sm:px-4">
      <ul className="list-none space-y-5">
        <HelpListItem afterText="You have nine guesses to fill out the grid." />
        <HelpListItem afterText="Select a Pokémon for each cell that matches the criteria for that cell's row and column." />
        <HelpListItem
          afterText={
            <>
              <strong>For types</strong>, the Pokémon must have at least one
              type that matches.
            </>
          }
        />
        <HelpListItem
          afterText={
            <>
              <strong>For other categories</strong>, click on the category name
              to find out about its critera.
            </>
          }
        />
        <HelpListItem
          afterText={
            <>
              <strong>Rarity scores</strong> are based on how unique your
              selections are vs. other PokeGrid players. Select unique Pokémon
              to keep your rarity score low!
            </>
          }
        />
        <HelpListItem
          afterText={
            <>
              You get <strong>one undo per day</strong> to help you correct an
              incorrect guess, or try to find a rarer selection to improve your
              score.
            </>
          }
        />
        <HelpListItem afterText="A Pokémon cannot be used more than once." />
        <HelpListItem afterText="A new grid is available each day at midnight!" />
      </ul>
    </div>
  );
}

function HelpListItem({
  bgColor = "",
  textColor = "",
  highlightText = "",
  afterText,
}) {
  return (
    <li>
      {highlightText !== "" && (
        <span
          className={`${bgColor} ${textColor} -m-0.5 inline-block rounded-sm px-1 py-0.5 font-medium`}
        >
          {highlightText}
        </span>
      )}{" "}
      {afterText}
    </li>
  );
}

export default HowToPlayDialog;
