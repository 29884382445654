export function dateToKey(date = null) {
  const d = date || new Date();
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

export function dateKeyToDate(dateString) {
  const [year, month, day] = dateString.split("-").map(Number);
  return new Date(year, month - 1, day);
}

export function getMidnight(date) {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}

export function getDaysBetweenDates(date1, date2) {
  const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
  const diffMilliseconds = Math.abs(new Date(date1) - new Date(date2));
  return Math.round(diffMilliseconds / oneDay);
}

export function addDays(date, days) {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() + days);
  return newDate;
}
