export function calcRarity(correctGuesses, globalStats) {
  let rarity = 0;
  if (correctGuesses) {
    for (let row = 0; row < 3; row++) {
      for (let col = 0; col < 3; col++) {
        const answer = correctGuesses[row][col];
        if (!answer) {
          rarity += 100;
        } else if (globalStats?.correctAnswersPlayerMap[row][col][answer]) {
          rarity +=
            (globalStats?.correctAnswersPlayerMap[row][col][answer] /
              globalStats?.completed) *
            100;
        }
      }
    }
  }

  if (rarity >= 100) {
    return Math.round(rarity);
  }

  return Math.round(rarity * 10) / 10;
}

export function calcAvgSquares(globalStats) {
  let overallPct = 0;

  for (let row = 0; row < 3; row++) {
    for (let col = 0; col < 3; col++) {
      overallPct += calcPctForSquare(globalStats, row, col);
    }
  }

  return overallPct;
}

export function calcPctForSquare(globalStats, row, col) {
  if (!globalStats) {
    return 0;
  }

  return (
    globalStats.correctGuesses[row][col] / Math.max(globalStats.completed, 1)
  );
}

export function calcAvgRarity(globalStats) {
  let rarity = 0;

  for (let row = 0; row < 3; row++) {
    for (let col = 0; col < 3; col++) {
      const correctAnswers = Object.values(
        globalStats.correctAnswersPlayerMap[row][col]
      );
      const totalCorrect = correctAnswers.reduce((a, b) => a + b, 0);
      const totalIncorrect = globalStats.completed - totalCorrect;

      const cellRarities = correctAnswers.map(
        (count) => count / globalStats.completed
      );

      const sumProduct = correctAnswers.reduce(function (r, a, i) {
        return r + a * cellRarities[i];
      }, 0);
      const cellRarity = (sumProduct + totalIncorrect) / globalStats.completed;

      rarity += cellRarity;
    }
  }

  return Math.round(rarity * 100, 1);
}
