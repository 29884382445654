export const hasImage = [];

export const pokemonList = [
  {
    id: 1,
    name: "bulbasaur",
    display_name: "Bulbasaur",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/1.png",
  },
  {
    id: 2,
    name: "ivysaur",
    display_name: "Ivysaur",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/2.png",
  },
  {
    id: 3,
    name: "venusaur",
    display_name: "Venusaur",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/3.png",
  },
  {
    id: 10033,
    name: "venusaur-mega",
    display_name: "Mega Venusaur",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10033.png",
  },
  {
    id: 10195,
    name: "venusaur-gmax",
    display_name: "Venusaur Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10195.png",
  },
  {
    id: 4,
    name: "charmander",
    display_name: "Charmander",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/4.png",
  },
  {
    id: 5,
    name: "charmeleon",
    display_name: "Charmeleon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/5.png",
  },
  {
    id: 6,
    name: "charizard",
    display_name: "Charizard",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/6.png",
  },
  {
    id: 10034,
    name: "charizard-mega-x",
    display_name: "Mega Charizard X",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10034.png",
  },
  {
    id: 10035,
    name: "charizard-mega-y",
    display_name: "Mega Charizard Y",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10035.png",
  },
  {
    id: 10196,
    name: "charizard-gmax",
    display_name: "Charizard Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10196.png",
  },
  {
    id: 7,
    name: "squirtle",
    display_name: "Squirtle",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/7.png",
  },
  {
    id: 8,
    name: "wartortle",
    display_name: "Wartortle",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/8.png",
  },
  {
    id: 9,
    name: "blastoise",
    display_name: "Blastoise",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/9.png",
  },
  {
    id: 10036,
    name: "blastoise-mega",
    display_name: "Mega Blastoise",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10036.png",
  },
  {
    id: 10197,
    name: "blastoise-gmax",
    display_name: "Blastoise Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10197.png",
  },
  {
    id: 10,
    name: "caterpie",
    display_name: "Caterpie",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10.png",
  },
  {
    id: 11,
    name: "metapod",
    display_name: "Metapod",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/11.png",
  },
  {
    id: 12,
    name: "butterfree",
    display_name: "Butterfree",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/12.png",
  },
  {
    id: 10198,
    name: "butterfree-gmax",
    display_name: "Butterfree Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10198.png",
  },
  {
    id: 13,
    name: "weedle",
    display_name: "Weedle",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/13.png",
  },
  {
    id: 14,
    name: "kakuna",
    display_name: "Kakuna",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/14.png",
  },
  {
    id: 15,
    name: "beedrill",
    display_name: "Beedrill",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/15.png",
  },
  {
    id: 10090,
    name: "beedrill-mega",
    display_name: "Mega Beedrill",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10090.png",
  },
  {
    id: 16,
    name: "pidgey",
    display_name: "Pidgey",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/16.png",
  },
  {
    id: 17,
    name: "pidgeotto",
    display_name: "Pidgeotto",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/17.png",
  },
  {
    id: 18,
    name: "pidgeot",
    display_name: "Pidgeot",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/18.png",
  },
  {
    id: 10073,
    name: "pidgeot-mega",
    display_name: "Mega Pidgeot",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10073.png",
  },
  {
    id: 19,
    name: "rattata",
    display_name: "Rattata",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/19.png",
  },
  {
    id: 10091,
    name: "rattata-alola",
    display_name: "Rattata Alolan Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10091.png",
  },
  {
    id: 20,
    name: "raticate",
    display_name: "Raticate",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/20.png",
  },
  {
    id: 10092,
    name: "raticate-alola",
    display_name: "Raticate Alolan Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10092.png",
  },
  {
    id: 10093,
    name: "raticate-totem-alola",
    display_name: "Raticate",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10093.png",
  },
  {
    id: 21,
    name: "spearow",
    display_name: "Spearow",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/21.png",
  },
  {
    id: 22,
    name: "fearow",
    display_name: "Fearow",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/22.png",
  },
  {
    id: 23,
    name: "ekans",
    display_name: "Ekans",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/23.png",
  },
  {
    id: 24,
    name: "arbok",
    display_name: "Arbok",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/24.png",
  },
  {
    id: 25,
    name: "pikachu",
    display_name: "Pikachu",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/25.png",
  },
  {
    id: 10080,
    name: "pikachu-rock-star",
    display_name: "Pikachu Pikachu Rock Star",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10080.png",
  },
  {
    id: 10081,
    name: "pikachu-belle",
    display_name: "Pikachu Pikachu Belle",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10081.png",
  },
  {
    id: 10082,
    name: "pikachu-pop-star",
    display_name: "Pikachu Pikachu Pop Star",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10082.png",
  },
  {
    id: 10083,
    name: "pikachu-phd",
    display_name: "Pikachu Pikachu Ph.D.",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10083.png",
  },
  {
    id: 10084,
    name: "pikachu-libre",
    display_name: "Pikachu Pikachu Libre",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10084.png",
  },
  {
    id: 10085,
    name: "pikachu-cosplay",
    display_name: "Pikachu Cosplay Pikachu",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10085.png",
  },
  {
    id: 10094,
    name: "pikachu-original-cap",
    display_name: "Pikachu Original Cap",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10094.png",
  },
  {
    id: 10095,
    name: "pikachu-hoenn-cap",
    display_name: "Pikachu Hoenn Cap",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10095.png",
  },
  {
    id: 10096,
    name: "pikachu-sinnoh-cap",
    display_name: "Pikachu Sinnoh Cap",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10096.png",
  },
  {
    id: 10097,
    name: "pikachu-unova-cap",
    display_name: "Pikachu Unova Cap",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10097.png",
  },
  {
    id: 10098,
    name: "pikachu-kalos-cap",
    display_name: "Pikachu Kalos Cap",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10098.png",
  },
  {
    id: 10099,
    name: "pikachu-alola-cap",
    display_name: "Pikachu Alola Cap",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10099.png",
  },
  {
    id: 10148,
    name: "pikachu-partner-cap",
    display_name: "Pikachu Partner Cap",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10148.png",
  },
  {
    id: 10158,
    name: "pikachu-starter",
    display_name: "Pikachu",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10158.png",
  },
  {
    id: 10160,
    name: "pikachu-world-cap",
    display_name: "Pikachu World Cap",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10160.png",
  },
  {
    id: 10199,
    name: "pikachu-gmax",
    display_name: "Pikachu Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10199.png",
  },
  {
    id: 26,
    name: "raichu",
    display_name: "Raichu",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/26.png",
  },
  {
    id: 10100,
    name: "raichu-alola",
    display_name: "Raichu Alolan Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10100.png",
  },
  {
    id: 27,
    name: "sandshrew",
    display_name: "Sandshrew",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/27.png",
  },
  {
    id: 10101,
    name: "sandshrew-alola",
    display_name: "Sandshrew Alolan Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10101.png",
  },
  {
    id: 28,
    name: "sandslash",
    display_name: "Sandslash",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/28.png",
  },
  {
    id: 10102,
    name: "sandslash-alola",
    display_name: "Sandslash Alolan Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10102.png",
  },
  {
    id: 29,
    name: "nidoran-f",
    display_name: "Nidoran♀",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/29.png",
  },
  {
    id: 30,
    name: "nidorina",
    display_name: "Nidorina",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/30.png",
  },
  {
    id: 31,
    name: "nidoqueen",
    display_name: "Nidoqueen",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/31.png",
  },
  {
    id: 32,
    name: "nidoran-m",
    display_name: "Nidoran♂",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/32.png",
  },
  {
    id: 33,
    name: "nidorino",
    display_name: "Nidorino",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/33.png",
  },
  {
    id: 34,
    name: "nidoking",
    display_name: "Nidoking",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/34.png",
  },
  {
    id: 35,
    name: "clefairy",
    display_name: "Clefairy",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/35.png",
  },
  {
    id: 36,
    name: "clefable",
    display_name: "Clefable",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/36.png",
  },
  {
    id: 37,
    name: "vulpix",
    display_name: "Vulpix",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/37.png",
  },
  {
    id: 10103,
    name: "vulpix-alola",
    display_name: "Vulpix Alolan Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10103.png",
  },
  {
    id: 38,
    name: "ninetales",
    display_name: "Ninetales",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/38.png",
  },
  {
    id: 10104,
    name: "ninetales-alola",
    display_name: "Ninetales Alolan Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10104.png",
  },
  {
    id: 39,
    name: "jigglypuff",
    display_name: "Jigglypuff",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/39.png",
  },
  {
    id: 40,
    name: "wigglytuff",
    display_name: "Wigglytuff",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/40.png",
  },
  {
    id: 41,
    name: "zubat",
    display_name: "Zubat",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/41.png",
  },
  {
    id: 42,
    name: "golbat",
    display_name: "Golbat",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/42.png",
  },
  {
    id: 43,
    name: "oddish",
    display_name: "Oddish",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/43.png",
  },
  {
    id: 44,
    name: "gloom",
    display_name: "Gloom",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/44.png",
  },
  {
    id: 45,
    name: "vileplume",
    display_name: "Vileplume",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/45.png",
  },
  {
    id: 46,
    name: "paras",
    display_name: "Paras",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/46.png",
  },
  {
    id: 47,
    name: "parasect",
    display_name: "Parasect",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/47.png",
  },
  {
    id: 48,
    name: "venonat",
    display_name: "Venonat",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/48.png",
  },
  {
    id: 49,
    name: "venomoth",
    display_name: "Venomoth",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/49.png",
  },
  {
    id: 50,
    name: "diglett",
    display_name: "Diglett",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/50.png",
  },
  {
    id: 10105,
    name: "diglett-alola",
    display_name: "Diglett Alolan Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10105.png",
  },
  {
    id: 51,
    name: "dugtrio",
    display_name: "Dugtrio",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/51.png",
  },
  {
    id: 10106,
    name: "dugtrio-alola",
    display_name: "Dugtrio Alolan Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10106.png",
  },
  {
    id: 52,
    name: "meowth",
    display_name: "Meowth",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/52.png",
  },
  {
    id: 10107,
    name: "meowth-alola",
    display_name: "Meowth Alolan Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10107.png",
  },
  {
    id: 10161,
    name: "meowth-galar",
    display_name: "Meowth Galarian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10161.png",
  },
  {
    id: 10200,
    name: "meowth-gmax",
    display_name: "Meowth Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10200.png",
  },
  {
    id: 53,
    name: "persian",
    display_name: "Persian",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/53.png",
  },
  {
    id: 10108,
    name: "persian-alola",
    display_name: "Persian Alolan Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10108.png",
  },
  {
    id: 54,
    name: "psyduck",
    display_name: "Psyduck",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/54.png",
  },
  {
    id: 55,
    name: "golduck",
    display_name: "Golduck",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/55.png",
  },
  {
    id: 56,
    name: "mankey",
    display_name: "Mankey",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/56.png",
  },
  {
    id: 57,
    name: "primeape",
    display_name: "Primeape",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/57.png",
  },
  {
    id: 58,
    name: "growlithe",
    display_name: "Growlithe",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/58.png",
  },
  {
    id: 10229,
    name: "growlithe-hisui",
    display_name: "Growlithe Hisuian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10229.png",
  },
  {
    id: 59,
    name: "arcanine",
    display_name: "Arcanine",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/59.png",
  },
  {
    id: 10230,
    name: "arcanine-hisui",
    display_name: "Arcanine Hisuian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10230.png",
  },
  {
    id: 60,
    name: "poliwag",
    display_name: "Poliwag",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/60.png",
  },
  {
    id: 61,
    name: "poliwhirl",
    display_name: "Poliwhirl",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/61.png",
  },
  {
    id: 62,
    name: "poliwrath",
    display_name: "Poliwrath",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/62.png",
  },
  {
    id: 63,
    name: "abra",
    display_name: "Abra",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/63.png",
  },
  {
    id: 64,
    name: "kadabra",
    display_name: "Kadabra",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/64.png",
  },
  {
    id: 65,
    name: "alakazam",
    display_name: "Alakazam",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/65.png",
  },
  {
    id: 10037,
    name: "alakazam-mega",
    display_name: "Mega Alakazam",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10037.png",
  },
  {
    id: 66,
    name: "machop",
    display_name: "Machop",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/66.png",
  },
  {
    id: 67,
    name: "machoke",
    display_name: "Machoke",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/67.png",
  },
  {
    id: 68,
    name: "machamp",
    display_name: "Machamp",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/68.png",
  },
  {
    id: 10201,
    name: "machamp-gmax",
    display_name: "Machamp Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10201.png",
  },
  {
    id: 69,
    name: "bellsprout",
    display_name: "Bellsprout",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/69.png",
  },
  {
    id: 70,
    name: "weepinbell",
    display_name: "Weepinbell",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/70.png",
  },
  {
    id: 71,
    name: "victreebel",
    display_name: "Victreebel",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/71.png",
  },
  {
    id: 72,
    name: "tentacool",
    display_name: "Tentacool",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/72.png",
  },
  {
    id: 73,
    name: "tentacruel",
    display_name: "Tentacruel",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/73.png",
  },
  {
    id: 74,
    name: "geodude",
    display_name: "Geodude",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/74.png",
  },
  {
    id: 10109,
    name: "geodude-alola",
    display_name: "Geodude Alolan Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10109.png",
  },
  {
    id: 75,
    name: "graveler",
    display_name: "Graveler",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/75.png",
  },
  {
    id: 10110,
    name: "graveler-alola",
    display_name: "Graveler Alolan Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10110.png",
  },
  {
    id: 76,
    name: "golem",
    display_name: "Golem",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/76.png",
  },
  {
    id: 10111,
    name: "golem-alola",
    display_name: "Golem Alolan Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10111.png",
  },
  {
    id: 77,
    name: "ponyta",
    display_name: "Ponyta",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/77.png",
  },
  {
    id: 10162,
    name: "ponyta-galar",
    display_name: "Ponyta Galarian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10162.png",
  },
  {
    id: 78,
    name: "rapidash",
    display_name: "Rapidash",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/78.png",
  },
  {
    id: 10163,
    name: "rapidash-galar",
    display_name: "Rapidash Galarian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10163.png",
  },
  {
    id: 79,
    name: "slowpoke",
    display_name: "Slowpoke",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/79.png",
  },
  {
    id: 10164,
    name: "slowpoke-galar",
    display_name: "Slowpoke Galarian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10164.png",
  },
  {
    id: 80,
    name: "slowbro",
    display_name: "Slowbro",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/80.png",
  },
  {
    id: 10071,
    name: "slowbro-mega",
    display_name: "Mega Slowbro",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10071.png",
  },
  {
    id: 10165,
    name: "slowbro-galar",
    display_name: "Slowbro Galarian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10165.png",
  },
  {
    id: 81,
    name: "magnemite",
    display_name: "Magnemite",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/81.png",
  },
  {
    id: 82,
    name: "magneton",
    display_name: "Magneton",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/82.png",
  },
  {
    id: 83,
    name: "farfetchd",
    display_name: "Farfetch’d",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/83.png",
  },
  {
    id: 10166,
    name: "farfetchd-galar",
    display_name: "Farfetch’d Galarian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10166.png",
  },
  {
    id: 84,
    name: "doduo",
    display_name: "Doduo",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/84.png",
  },
  {
    id: 85,
    name: "dodrio",
    display_name: "Dodrio",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/85.png",
  },
  {
    id: 86,
    name: "seel",
    display_name: "Seel",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/86.png",
  },
  {
    id: 87,
    name: "dewgong",
    display_name: "Dewgong",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/87.png",
  },
  {
    id: 88,
    name: "grimer",
    display_name: "Grimer",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/88.png",
  },
  {
    id: 10112,
    name: "grimer-alola",
    display_name: "Grimer Alolan Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10112.png",
  },
  {
    id: 89,
    name: "muk",
    display_name: "Muk",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/89.png",
  },
  {
    id: 10113,
    name: "muk-alola",
    display_name: "Muk Alolan Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10113.png",
  },
  {
    id: 90,
    name: "shellder",
    display_name: "Shellder",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/90.png",
  },
  {
    id: 91,
    name: "cloyster",
    display_name: "Cloyster",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/91.png",
  },
  {
    id: 92,
    name: "gastly",
    display_name: "Gastly",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/92.png",
  },
  {
    id: 93,
    name: "haunter",
    display_name: "Haunter",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/93.png",
  },
  {
    id: 94,
    name: "gengar",
    display_name: "Gengar",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/94.png",
  },
  {
    id: 10038,
    name: "gengar-mega",
    display_name: "Mega Gengar",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10038.png",
  },
  {
    id: 10202,
    name: "gengar-gmax",
    display_name: "Gengar Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10202.png",
  },
  {
    id: 95,
    name: "onix",
    display_name: "Onix",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/95.png",
  },
  {
    id: 96,
    name: "drowzee",
    display_name: "Drowzee",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/96.png",
  },
  {
    id: 97,
    name: "hypno",
    display_name: "Hypno",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/97.png",
  },
  {
    id: 98,
    name: "krabby",
    display_name: "Krabby",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/98.png",
  },
  {
    id: 99,
    name: "kingler",
    display_name: "Kingler",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/99.png",
  },
  {
    id: 10203,
    name: "kingler-gmax",
    display_name: "Kingler Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10203.png",
  },
  {
    id: 100,
    name: "voltorb",
    display_name: "Voltorb",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/100.png",
  },
  {
    id: 10231,
    name: "voltorb-hisui",
    display_name: "Voltorb Hisuian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10231.png",
  },
  {
    id: 101,
    name: "electrode",
    display_name: "Electrode",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/101.png",
  },
  {
    id: 10232,
    name: "electrode-hisui",
    display_name: "Electrode Hisuian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10232.png",
  },
  {
    id: 102,
    name: "exeggcute",
    display_name: "Exeggcute",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/102.png",
  },
  {
    id: 103,
    name: "exeggutor",
    display_name: "Exeggutor",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/103.png",
  },
  {
    id: 10114,
    name: "exeggutor-alola",
    display_name: "Exeggutor Alolan Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10114.png",
  },
  {
    id: 104,
    name: "cubone",
    display_name: "Cubone",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/104.png",
  },
  {
    id: 105,
    name: "marowak",
    display_name: "Marowak",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/105.png",
  },
  {
    id: 10115,
    name: "marowak-alola",
    display_name: "Marowak Alolan Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10115.png",
  },
  {
    id: 10149,
    name: "marowak-totem",
    display_name: "Marowak",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10149.png",
  },
  {
    id: 106,
    name: "hitmonlee",
    display_name: "Hitmonlee",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/106.png",
  },
  {
    id: 107,
    name: "hitmonchan",
    display_name: "Hitmonchan",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/107.png",
  },
  {
    id: 108,
    name: "lickitung",
    display_name: "Lickitung",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/108.png",
  },
  {
    id: 109,
    name: "koffing",
    display_name: "Koffing",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/109.png",
  },
  {
    id: 110,
    name: "weezing",
    display_name: "Weezing",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/110.png",
  },
  {
    id: 10167,
    name: "weezing-galar",
    display_name: "Weezing Galarian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10167.png",
  },
  {
    id: 111,
    name: "rhyhorn",
    display_name: "Rhyhorn",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/111.png",
  },
  {
    id: 112,
    name: "rhydon",
    display_name: "Rhydon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/112.png",
  },
  {
    id: 113,
    name: "chansey",
    display_name: "Chansey",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/113.png",
  },
  {
    id: 114,
    name: "tangela",
    display_name: "Tangela",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/114.png",
  },
  {
    id: 115,
    name: "kangaskhan",
    display_name: "Kangaskhan",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/115.png",
  },
  {
    id: 10039,
    name: "kangaskhan-mega",
    display_name: "Mega Kangaskhan",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10039.png",
  },
  {
    id: 116,
    name: "horsea",
    display_name: "Horsea",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/116.png",
  },
  {
    id: 117,
    name: "seadra",
    display_name: "Seadra",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/117.png",
  },
  {
    id: 118,
    name: "goldeen",
    display_name: "Goldeen",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/118.png",
  },
  {
    id: 119,
    name: "seaking",
    display_name: "Seaking",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/119.png",
  },
  {
    id: 120,
    name: "staryu",
    display_name: "Staryu",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/120.png",
  },
  {
    id: 121,
    name: "starmie",
    display_name: "Starmie",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/121.png",
  },
  {
    id: 122,
    name: "mr-mime",
    display_name: "Mr. Mime",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/122.png",
  },
  {
    id: 10168,
    name: "mr-mime-galar",
    display_name: "Mr. Mime Galarian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10168.png",
  },
  {
    id: 123,
    name: "scyther",
    display_name: "Scyther",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/123.png",
  },
  {
    id: 124,
    name: "jynx",
    display_name: "Jynx",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/124.png",
  },
  {
    id: 125,
    name: "electabuzz",
    display_name: "Electabuzz",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/125.png",
  },
  {
    id: 126,
    name: "magmar",
    display_name: "Magmar",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/126.png",
  },
  {
    id: 127,
    name: "pinsir",
    display_name: "Pinsir",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/127.png",
  },
  {
    id: 10040,
    name: "pinsir-mega",
    display_name: "Mega Pinsir",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10040.png",
  },
  {
    id: 128,
    name: "tauros",
    display_name: "Tauros",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/128.png",
  },
  {
    id: 10250,
    name: "tauros-paldea-combat-breed",
    display_name: "Tauros Paldea Combat Breed",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10250.png",
  },
  {
    id: 10251,
    name: "tauros-paldea-blaze-breed",
    display_name: "Tauros Paldea Blaze Breed",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10251.png",
  },
  {
    id: 10252,
    name: "tauros-paldea-aqua-breed",
    display_name: "Tauros Paldea Aqua Breed",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10252.png",
  },
  {
    id: 129,
    name: "magikarp",
    display_name: "Magikarp",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/129.png",
  },
  {
    id: 130,
    name: "gyarados",
    display_name: "Gyarados",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/130.png",
  },
  {
    id: 10041,
    name: "gyarados-mega",
    display_name: "Mega Gyarados",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10041.png",
  },
  {
    id: 131,
    name: "lapras",
    display_name: "Lapras",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/131.png",
  },
  {
    id: 10204,
    name: "lapras-gmax",
    display_name: "Lapras Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10204.png",
  },
  {
    id: 132,
    name: "ditto",
    display_name: "Ditto",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/132.png",
  },
  {
    id: 133,
    name: "eevee",
    display_name: "Eevee",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/133.png",
  },
  {
    id: 10159,
    name: "eevee-starter",
    display_name: "Eevee",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10159.png",
  },
  {
    id: 10205,
    name: "eevee-gmax",
    display_name: "Eevee Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10205.png",
  },
  {
    id: 134,
    name: "vaporeon",
    display_name: "Vaporeon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/134.png",
  },
  {
    id: 135,
    name: "jolteon",
    display_name: "Jolteon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/135.png",
  },
  {
    id: 136,
    name: "flareon",
    display_name: "Flareon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/136.png",
  },
  {
    id: 137,
    name: "porygon",
    display_name: "Porygon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/137.png",
  },
  {
    id: 138,
    name: "omanyte",
    display_name: "Omanyte",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/138.png",
  },
  {
    id: 139,
    name: "omastar",
    display_name: "Omastar",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/139.png",
  },
  {
    id: 140,
    name: "kabuto",
    display_name: "Kabuto",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/140.png",
  },
  {
    id: 141,
    name: "kabutops",
    display_name: "Kabutops",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/141.png",
  },
  {
    id: 142,
    name: "aerodactyl",
    display_name: "Aerodactyl",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/142.png",
  },
  {
    id: 10042,
    name: "aerodactyl-mega",
    display_name: "Mega Aerodactyl",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10042.png",
  },
  {
    id: 143,
    name: "snorlax",
    display_name: "Snorlax",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/143.png",
  },
  {
    id: 10206,
    name: "snorlax-gmax",
    display_name: "Snorlax Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10206.png",
  },
  {
    id: 144,
    name: "articuno",
    display_name: "Articuno",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/144.png",
  },
  {
    id: 10169,
    name: "articuno-galar",
    display_name: "Articuno Galarian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10169.png",
  },
  {
    id: 145,
    name: "zapdos",
    display_name: "Zapdos",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/145.png",
  },
  {
    id: 10170,
    name: "zapdos-galar",
    display_name: "Zapdos Galarian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10170.png",
  },
  {
    id: 146,
    name: "moltres",
    display_name: "Moltres",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/146.png",
  },
  {
    id: 10171,
    name: "moltres-galar",
    display_name: "Moltres Galarian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10171.png",
  },
  {
    id: 147,
    name: "dratini",
    display_name: "Dratini",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/147.png",
  },
  {
    id: 148,
    name: "dragonair",
    display_name: "Dragonair",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/148.png",
  },
  {
    id: 149,
    name: "dragonite",
    display_name: "Dragonite",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/149.png",
  },
  {
    id: 150,
    name: "mewtwo",
    display_name: "Mewtwo",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/150.png",
  },
  {
    id: 10043,
    name: "mewtwo-mega-x",
    display_name: "Mega Mewtwo X",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10043.png",
  },
  {
    id: 10044,
    name: "mewtwo-mega-y",
    display_name: "Mega Mewtwo Y",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10044.png",
  },
  {
    id: 151,
    name: "mew",
    display_name: "Mew",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/151.png",
  },
  {
    id: 152,
    name: "chikorita",
    display_name: "Chikorita",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/152.png",
  },
  {
    id: 153,
    name: "bayleef",
    display_name: "Bayleef",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/153.png",
  },
  {
    id: 154,
    name: "meganium",
    display_name: "Meganium",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/154.png",
  },
  {
    id: 155,
    name: "cyndaquil",
    display_name: "Cyndaquil",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/155.png",
  },
  {
    id: 156,
    name: "quilava",
    display_name: "Quilava",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/156.png",
  },
  {
    id: 157,
    name: "typhlosion",
    display_name: "Typhlosion",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/157.png",
  },
  {
    id: 10233,
    name: "typhlosion-hisui",
    display_name: "Typhlosion Hisuian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10233.png",
  },
  {
    id: 158,
    name: "totodile",
    display_name: "Totodile",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/158.png",
  },
  {
    id: 159,
    name: "croconaw",
    display_name: "Croconaw",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/159.png",
  },
  {
    id: 160,
    name: "feraligatr",
    display_name: "Feraligatr",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/160.png",
  },
  {
    id: 161,
    name: "sentret",
    display_name: "Sentret",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/161.png",
  },
  {
    id: 162,
    name: "furret",
    display_name: "Furret",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/162.png",
  },
  {
    id: 163,
    name: "hoothoot",
    display_name: "Hoothoot",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/163.png",
  },
  {
    id: 164,
    name: "noctowl",
    display_name: "Noctowl",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/164.png",
  },
  {
    id: 165,
    name: "ledyba",
    display_name: "Ledyba",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/165.png",
  },
  {
    id: 166,
    name: "ledian",
    display_name: "Ledian",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/166.png",
  },
  {
    id: 167,
    name: "spinarak",
    display_name: "Spinarak",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/167.png",
  },
  {
    id: 168,
    name: "ariados",
    display_name: "Ariados",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/168.png",
  },
  {
    id: 169,
    name: "crobat",
    display_name: "Crobat",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/169.png",
  },
  {
    id: 170,
    name: "chinchou",
    display_name: "Chinchou",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/170.png",
  },
  {
    id: 171,
    name: "lanturn",
    display_name: "Lanturn",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/171.png",
  },
  {
    id: 172,
    name: "pichu",
    display_name: "Pichu",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/172.png",
  },
  {
    id: 173,
    name: "cleffa",
    display_name: "Cleffa",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/173.png",
  },
  {
    id: 174,
    name: "igglybuff",
    display_name: "Igglybuff",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/174.png",
  },
  {
    id: 175,
    name: "togepi",
    display_name: "Togepi",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/175.png",
  },
  {
    id: 176,
    name: "togetic",
    display_name: "Togetic",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/176.png",
  },
  {
    id: 177,
    name: "natu",
    display_name: "Natu",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/177.png",
  },
  {
    id: 178,
    name: "xatu",
    display_name: "Xatu",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/178.png",
  },
  {
    id: 179,
    name: "mareep",
    display_name: "Mareep",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/179.png",
  },
  {
    id: 180,
    name: "flaaffy",
    display_name: "Flaaffy",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/180.png",
  },
  {
    id: 181,
    name: "ampharos",
    display_name: "Ampharos",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/181.png",
  },
  {
    id: 10045,
    name: "ampharos-mega",
    display_name: "Mega Ampharos",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10045.png",
  },
  {
    id: 182,
    name: "bellossom",
    display_name: "Bellossom",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/182.png",
  },
  {
    id: 183,
    name: "marill",
    display_name: "Marill",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/183.png",
  },
  {
    id: 184,
    name: "azumarill",
    display_name: "Azumarill",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/184.png",
  },
  {
    id: 185,
    name: "sudowoodo",
    display_name: "Sudowoodo",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/185.png",
  },
  {
    id: 186,
    name: "politoed",
    display_name: "Politoed",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/186.png",
  },
  {
    id: 187,
    name: "hoppip",
    display_name: "Hoppip",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/187.png",
  },
  {
    id: 188,
    name: "skiploom",
    display_name: "Skiploom",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/188.png",
  },
  {
    id: 189,
    name: "jumpluff",
    display_name: "Jumpluff",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/189.png",
  },
  {
    id: 190,
    name: "aipom",
    display_name: "Aipom",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/190.png",
  },
  {
    id: 191,
    name: "sunkern",
    display_name: "Sunkern",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/191.png",
  },
  {
    id: 192,
    name: "sunflora",
    display_name: "Sunflora",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/192.png",
  },
  {
    id: 193,
    name: "yanma",
    display_name: "Yanma",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/193.png",
  },
  {
    id: 194,
    name: "wooper",
    display_name: "Wooper",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/194.png",
  },
  {
    id: 10253,
    name: "wooper-paldea",
    display_name: "Wooper Paldean Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10253.png",
  },
  {
    id: 195,
    name: "quagsire",
    display_name: "Quagsire",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/195.png",
  },
  {
    id: 196,
    name: "espeon",
    display_name: "Espeon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/196.png",
  },
  {
    id: 197,
    name: "umbreon",
    display_name: "Umbreon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/197.png",
  },
  {
    id: 198,
    name: "murkrow",
    display_name: "Murkrow",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/198.png",
  },
  {
    id: 199,
    name: "slowking",
    display_name: "Slowking",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/199.png",
  },
  {
    id: 10172,
    name: "slowking-galar",
    display_name: "Slowking Galarian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10172.png",
  },
  {
    id: 200,
    name: "misdreavus",
    display_name: "Misdreavus",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/200.png",
  },
  {
    id: 201,
    name: "unown",
    display_name: "Unown",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/201.png",
  },
  {
    id: 202,
    name: "wobbuffet",
    display_name: "Wobbuffet",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/202.png",
  },
  {
    id: 203,
    name: "girafarig",
    display_name: "Girafarig",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/203.png",
  },
  {
    id: 204,
    name: "pineco",
    display_name: "Pineco",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/204.png",
  },
  {
    id: 205,
    name: "forretress",
    display_name: "Forretress",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/205.png",
  },
  {
    id: 206,
    name: "dunsparce",
    display_name: "Dunsparce",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/206.png",
  },
  {
    id: 207,
    name: "gligar",
    display_name: "Gligar",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/207.png",
  },
  {
    id: 208,
    name: "steelix",
    display_name: "Steelix",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/208.png",
  },
  {
    id: 10072,
    name: "steelix-mega",
    display_name: "Mega Steelix",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10072.png",
  },
  {
    id: 209,
    name: "snubbull",
    display_name: "Snubbull",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/209.png",
  },
  {
    id: 210,
    name: "granbull",
    display_name: "Granbull",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/210.png",
  },
  {
    id: 211,
    name: "qwilfish",
    display_name: "Qwilfish",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/211.png",
  },
  {
    id: 10234,
    name: "qwilfish-hisui",
    display_name: "Qwilfish Hisuian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10234.png",
  },
  {
    id: 212,
    name: "scizor",
    display_name: "Scizor",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/212.png",
  },
  {
    id: 10046,
    name: "scizor-mega",
    display_name: "Mega Scizor",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10046.png",
  },
  {
    id: 213,
    name: "shuckle",
    display_name: "Shuckle",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/213.png",
  },
  {
    id: 214,
    name: "heracross",
    display_name: "Heracross",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/214.png",
  },
  {
    id: 10047,
    name: "heracross-mega",
    display_name: "Mega Heracross",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10047.png",
  },
  {
    id: 215,
    name: "sneasel",
    display_name: "Sneasel",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/215.png",
  },
  {
    id: 10235,
    name: "sneasel-hisui",
    display_name: "Sneasel Hisuian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10235.png",
  },
  {
    id: 216,
    name: "teddiursa",
    display_name: "Teddiursa",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/216.png",
  },
  {
    id: 217,
    name: "ursaring",
    display_name: "Ursaring",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/217.png",
  },
  {
    id: 218,
    name: "slugma",
    display_name: "Slugma",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/218.png",
  },
  {
    id: 219,
    name: "magcargo",
    display_name: "Magcargo",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/219.png",
  },
  {
    id: 220,
    name: "swinub",
    display_name: "Swinub",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/220.png",
  },
  {
    id: 221,
    name: "piloswine",
    display_name: "Piloswine",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/221.png",
  },
  {
    id: 222,
    name: "corsola",
    display_name: "Corsola",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/222.png",
  },
  {
    id: 10173,
    name: "corsola-galar",
    display_name: "Corsola Galarian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10173.png",
  },
  {
    id: 223,
    name: "remoraid",
    display_name: "Remoraid",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/223.png",
  },
  {
    id: 224,
    name: "octillery",
    display_name: "Octillery",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/224.png",
  },
  {
    id: 225,
    name: "delibird",
    display_name: "Delibird",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/225.png",
  },
  {
    id: 226,
    name: "mantine",
    display_name: "Mantine",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/226.png",
  },
  {
    id: 227,
    name: "skarmory",
    display_name: "Skarmory",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/227.png",
  },
  {
    id: 228,
    name: "houndour",
    display_name: "Houndour",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/228.png",
  },
  {
    id: 229,
    name: "houndoom",
    display_name: "Houndoom",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/229.png",
  },
  {
    id: 10048,
    name: "houndoom-mega",
    display_name: "Mega Houndoom",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10048.png",
  },
  {
    id: 230,
    name: "kingdra",
    display_name: "Kingdra",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/230.png",
  },
  {
    id: 231,
    name: "phanpy",
    display_name: "Phanpy",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/231.png",
  },
  {
    id: 232,
    name: "donphan",
    display_name: "Donphan",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/232.png",
  },
  {
    id: 233,
    name: "porygon2",
    display_name: "Porygon2",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/233.png",
  },
  {
    id: 234,
    name: "stantler",
    display_name: "Stantler",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/234.png",
  },
  {
    id: 235,
    name: "smeargle",
    display_name: "Smeargle",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/235.png",
  },
  {
    id: 236,
    name: "tyrogue",
    display_name: "Tyrogue",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/236.png",
  },
  {
    id: 237,
    name: "hitmontop",
    display_name: "Hitmontop",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/237.png",
  },
  {
    id: 238,
    name: "smoochum",
    display_name: "Smoochum",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/238.png",
  },
  {
    id: 239,
    name: "elekid",
    display_name: "Elekid",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/239.png",
  },
  {
    id: 240,
    name: "magby",
    display_name: "Magby",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/240.png",
  },
  {
    id: 241,
    name: "miltank",
    display_name: "Miltank",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/241.png",
  },
  {
    id: 242,
    name: "blissey",
    display_name: "Blissey",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/242.png",
  },
  {
    id: 243,
    name: "raikou",
    display_name: "Raikou",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/243.png",
  },
  {
    id: 244,
    name: "entei",
    display_name: "Entei",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/244.png",
  },
  {
    id: 245,
    name: "suicune",
    display_name: "Suicune",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/245.png",
  },
  {
    id: 246,
    name: "larvitar",
    display_name: "Larvitar",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/246.png",
  },
  {
    id: 247,
    name: "pupitar",
    display_name: "Pupitar",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/247.png",
  },
  {
    id: 248,
    name: "tyranitar",
    display_name: "Tyranitar",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/248.png",
  },
  {
    id: 10049,
    name: "tyranitar-mega",
    display_name: "Mega Tyranitar",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10049.png",
  },
  {
    id: 249,
    name: "lugia",
    display_name: "Lugia",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/249.png",
  },
  {
    id: 250,
    name: "ho-oh",
    display_name: "Ho-Oh",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/250.png",
  },
  {
    id: 251,
    name: "celebi",
    display_name: "Celebi",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/251.png",
  },
  {
    id: 252,
    name: "treecko",
    display_name: "Treecko",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/252.png",
  },
  {
    id: 253,
    name: "grovyle",
    display_name: "Grovyle",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/253.png",
  },
  {
    id: 254,
    name: "sceptile",
    display_name: "Sceptile",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/254.png",
  },
  {
    id: 10065,
    name: "sceptile-mega",
    display_name: "Mega Sceptile",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10065.png",
  },
  {
    id: 255,
    name: "torchic",
    display_name: "Torchic",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/255.png",
  },
  {
    id: 256,
    name: "combusken",
    display_name: "Combusken",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/256.png",
  },
  {
    id: 257,
    name: "blaziken",
    display_name: "Blaziken",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/257.png",
  },
  {
    id: 10050,
    name: "blaziken-mega",
    display_name: "Mega Blaziken",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10050.png",
  },
  {
    id: 258,
    name: "mudkip",
    display_name: "Mudkip",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/258.png",
  },
  {
    id: 259,
    name: "marshtomp",
    display_name: "Marshtomp",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/259.png",
  },
  {
    id: 260,
    name: "swampert",
    display_name: "Swampert",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/260.png",
  },
  {
    id: 10064,
    name: "swampert-mega",
    display_name: "Mega Swampert",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10064.png",
  },
  {
    id: 261,
    name: "poochyena",
    display_name: "Poochyena",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/261.png",
  },
  {
    id: 262,
    name: "mightyena",
    display_name: "Mightyena",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/262.png",
  },
  {
    id: 263,
    name: "zigzagoon",
    display_name: "Zigzagoon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/263.png",
  },
  {
    id: 10174,
    name: "zigzagoon-galar",
    display_name: "Zigzagoon Galarian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10174.png",
  },
  {
    id: 264,
    name: "linoone",
    display_name: "Linoone",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/264.png",
  },
  {
    id: 10175,
    name: "linoone-galar",
    display_name: "Linoone Galarian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10175.png",
  },
  {
    id: 265,
    name: "wurmple",
    display_name: "Wurmple",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/265.png",
  },
  {
    id: 266,
    name: "silcoon",
    display_name: "Silcoon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/266.png",
  },
  {
    id: 267,
    name: "beautifly",
    display_name: "Beautifly",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/267.png",
  },
  {
    id: 268,
    name: "cascoon",
    display_name: "Cascoon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/268.png",
  },
  {
    id: 269,
    name: "dustox",
    display_name: "Dustox",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/269.png",
  },
  {
    id: 270,
    name: "lotad",
    display_name: "Lotad",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/270.png",
  },
  {
    id: 271,
    name: "lombre",
    display_name: "Lombre",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/271.png",
  },
  {
    id: 272,
    name: "ludicolo",
    display_name: "Ludicolo",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/272.png",
  },
  {
    id: 273,
    name: "seedot",
    display_name: "Seedot",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/273.png",
  },
  {
    id: 274,
    name: "nuzleaf",
    display_name: "Nuzleaf",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/274.png",
  },
  {
    id: 275,
    name: "shiftry",
    display_name: "Shiftry",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/275.png",
  },
  {
    id: 276,
    name: "taillow",
    display_name: "Taillow",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/276.png",
  },
  {
    id: 277,
    name: "swellow",
    display_name: "Swellow",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/277.png",
  },
  {
    id: 278,
    name: "wingull",
    display_name: "Wingull",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/278.png",
  },
  {
    id: 279,
    name: "pelipper",
    display_name: "Pelipper",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/279.png",
  },
  {
    id: 280,
    name: "ralts",
    display_name: "Ralts",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/280.png",
  },
  {
    id: 281,
    name: "kirlia",
    display_name: "Kirlia",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/281.png",
  },
  {
    id: 282,
    name: "gardevoir",
    display_name: "Gardevoir",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/282.png",
  },
  {
    id: 10051,
    name: "gardevoir-mega",
    display_name: "Mega Gardevoir",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10051.png",
  },
  {
    id: 283,
    name: "surskit",
    display_name: "Surskit",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/283.png",
  },
  {
    id: 284,
    name: "masquerain",
    display_name: "Masquerain",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/284.png",
  },
  {
    id: 285,
    name: "shroomish",
    display_name: "Shroomish",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/285.png",
  },
  {
    id: 286,
    name: "breloom",
    display_name: "Breloom",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/286.png",
  },
  {
    id: 287,
    name: "slakoth",
    display_name: "Slakoth",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/287.png",
  },
  {
    id: 288,
    name: "vigoroth",
    display_name: "Vigoroth",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/288.png",
  },
  {
    id: 289,
    name: "slaking",
    display_name: "Slaking",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/289.png",
  },
  {
    id: 290,
    name: "nincada",
    display_name: "Nincada",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/290.png",
  },
  {
    id: 291,
    name: "ninjask",
    display_name: "Ninjask",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/291.png",
  },
  {
    id: 292,
    name: "shedinja",
    display_name: "Shedinja",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/292.png",
  },
  {
    id: 293,
    name: "whismur",
    display_name: "Whismur",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/293.png",
  },
  {
    id: 294,
    name: "loudred",
    display_name: "Loudred",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/294.png",
  },
  {
    id: 295,
    name: "exploud",
    display_name: "Exploud",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/295.png",
  },
  {
    id: 296,
    name: "makuhita",
    display_name: "Makuhita",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/296.png",
  },
  {
    id: 297,
    name: "hariyama",
    display_name: "Hariyama",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/297.png",
  },
  {
    id: 298,
    name: "azurill",
    display_name: "Azurill",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/298.png",
  },
  {
    id: 299,
    name: "nosepass",
    display_name: "Nosepass",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/299.png",
  },
  {
    id: 300,
    name: "skitty",
    display_name: "Skitty",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/300.png",
  },
  {
    id: 301,
    name: "delcatty",
    display_name: "Delcatty",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/301.png",
  },
  {
    id: 302,
    name: "sableye",
    display_name: "Sableye",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/302.png",
  },
  {
    id: 10066,
    name: "sableye-mega",
    display_name: "Mega Sableye",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10066.png",
  },
  {
    id: 303,
    name: "mawile",
    display_name: "Mawile",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/303.png",
  },
  {
    id: 10052,
    name: "mawile-mega",
    display_name: "Mega Mawile",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10052.png",
  },
  {
    id: 304,
    name: "aron",
    display_name: "Aron",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/304.png",
  },
  {
    id: 305,
    name: "lairon",
    display_name: "Lairon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/305.png",
  },
  {
    id: 306,
    name: "aggron",
    display_name: "Aggron",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/306.png",
  },
  {
    id: 10053,
    name: "aggron-mega",
    display_name: "Mega Aggron",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10053.png",
  },
  {
    id: 307,
    name: "meditite",
    display_name: "Meditite",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/307.png",
  },
  {
    id: 308,
    name: "medicham",
    display_name: "Medicham",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/308.png",
  },
  {
    id: 10054,
    name: "medicham-mega",
    display_name: "Mega Medicham",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10054.png",
  },
  {
    id: 309,
    name: "electrike",
    display_name: "Electrike",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/309.png",
  },
  {
    id: 310,
    name: "manectric",
    display_name: "Manectric",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/310.png",
  },
  {
    id: 10055,
    name: "manectric-mega",
    display_name: "Mega Manectric",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10055.png",
  },
  {
    id: 311,
    name: "plusle",
    display_name: "Plusle",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/311.png",
  },
  {
    id: 312,
    name: "minun",
    display_name: "Minun",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/312.png",
  },
  {
    id: 313,
    name: "volbeat",
    display_name: "Volbeat",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/313.png",
  },
  {
    id: 314,
    name: "illumise",
    display_name: "Illumise",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/314.png",
  },
  {
    id: 315,
    name: "roselia",
    display_name: "Roselia",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/315.png",
  },
  {
    id: 316,
    name: "gulpin",
    display_name: "Gulpin",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/316.png",
  },
  {
    id: 317,
    name: "swalot",
    display_name: "Swalot",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/317.png",
  },
  {
    id: 318,
    name: "carvanha",
    display_name: "Carvanha",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/318.png",
  },
  {
    id: 319,
    name: "sharpedo",
    display_name: "Sharpedo",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/319.png",
  },
  {
    id: 10070,
    name: "sharpedo-mega",
    display_name: "Mega Sharpedo",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10070.png",
  },
  {
    id: 320,
    name: "wailmer",
    display_name: "Wailmer",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/320.png",
  },
  {
    id: 321,
    name: "wailord",
    display_name: "Wailord",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/321.png",
  },
  {
    id: 322,
    name: "numel",
    display_name: "Numel",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/322.png",
  },
  {
    id: 323,
    name: "camerupt",
    display_name: "Camerupt",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/323.png",
  },
  {
    id: 10087,
    name: "camerupt-mega",
    display_name: "Mega Camerupt",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10087.png",
  },
  {
    id: 324,
    name: "torkoal",
    display_name: "Torkoal",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/324.png",
  },
  {
    id: 325,
    name: "spoink",
    display_name: "Spoink",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/325.png",
  },
  {
    id: 326,
    name: "grumpig",
    display_name: "Grumpig",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/326.png",
  },
  {
    id: 327,
    name: "spinda",
    display_name: "Spinda",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/327.png",
  },
  {
    id: 328,
    name: "trapinch",
    display_name: "Trapinch",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/328.png",
  },
  {
    id: 329,
    name: "vibrava",
    display_name: "Vibrava",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/329.png",
  },
  {
    id: 330,
    name: "flygon",
    display_name: "Flygon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/330.png",
  },
  {
    id: 331,
    name: "cacnea",
    display_name: "Cacnea",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/331.png",
  },
  {
    id: 332,
    name: "cacturne",
    display_name: "Cacturne",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/332.png",
  },
  {
    id: 333,
    name: "swablu",
    display_name: "Swablu",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/333.png",
  },
  {
    id: 334,
    name: "altaria",
    display_name: "Altaria",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/334.png",
  },
  {
    id: 10067,
    name: "altaria-mega",
    display_name: "Mega Altaria",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10067.png",
  },
  {
    id: 335,
    name: "zangoose",
    display_name: "Zangoose",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/335.png",
  },
  {
    id: 336,
    name: "seviper",
    display_name: "Seviper",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/336.png",
  },
  {
    id: 337,
    name: "lunatone",
    display_name: "Lunatone",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/337.png",
  },
  {
    id: 338,
    name: "solrock",
    display_name: "Solrock",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/338.png",
  },
  {
    id: 339,
    name: "barboach",
    display_name: "Barboach",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/339.png",
  },
  {
    id: 340,
    name: "whiscash",
    display_name: "Whiscash",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/340.png",
  },
  {
    id: 341,
    name: "corphish",
    display_name: "Corphish",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/341.png",
  },
  {
    id: 342,
    name: "crawdaunt",
    display_name: "Crawdaunt",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/342.png",
  },
  {
    id: 343,
    name: "baltoy",
    display_name: "Baltoy",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/343.png",
  },
  {
    id: 344,
    name: "claydol",
    display_name: "Claydol",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/344.png",
  },
  {
    id: 345,
    name: "lileep",
    display_name: "Lileep",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/345.png",
  },
  {
    id: 346,
    name: "cradily",
    display_name: "Cradily",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/346.png",
  },
  {
    id: 347,
    name: "anorith",
    display_name: "Anorith",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/347.png",
  },
  {
    id: 348,
    name: "armaldo",
    display_name: "Armaldo",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/348.png",
  },
  {
    id: 349,
    name: "feebas",
    display_name: "Feebas",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/349.png",
  },
  {
    id: 350,
    name: "milotic",
    display_name: "Milotic",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/350.png",
  },
  {
    id: 351,
    name: "castform",
    display_name: "Castform Normal",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/351.png",
  },
  {
    id: 10013,
    name: "castform-sunny",
    display_name: "Castform Sunny Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10013.png",
  },
  {
    id: 10014,
    name: "castform-rainy",
    display_name: "Castform Rainy Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10014.png",
  },
  {
    id: 10015,
    name: "castform-snowy",
    display_name: "Castform Snowy Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10015.png",
  },
  {
    id: 352,
    name: "kecleon",
    display_name: "Kecleon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/352.png",
  },
  {
    id: 353,
    name: "shuppet",
    display_name: "Shuppet",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/353.png",
  },
  {
    id: 354,
    name: "banette",
    display_name: "Banette",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/354.png",
  },
  {
    id: 10056,
    name: "banette-mega",
    display_name: "Mega Banette",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10056.png",
  },
  {
    id: 355,
    name: "duskull",
    display_name: "Duskull",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/355.png",
  },
  {
    id: 356,
    name: "dusclops",
    display_name: "Dusclops",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/356.png",
  },
  {
    id: 357,
    name: "tropius",
    display_name: "Tropius",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/357.png",
  },
  {
    id: 358,
    name: "chimecho",
    display_name: "Chimecho",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/358.png",
  },
  {
    id: 359,
    name: "absol",
    display_name: "Absol",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/359.png",
  },
  {
    id: 10057,
    name: "absol-mega",
    display_name: "Mega Absol",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10057.png",
  },
  {
    id: 360,
    name: "wynaut",
    display_name: "Wynaut",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/360.png",
  },
  {
    id: 361,
    name: "snorunt",
    display_name: "Snorunt",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/361.png",
  },
  {
    id: 362,
    name: "glalie",
    display_name: "Glalie",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/362.png",
  },
  {
    id: 10074,
    name: "glalie-mega",
    display_name: "Mega Glalie",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10074.png",
  },
  {
    id: 363,
    name: "spheal",
    display_name: "Spheal",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/363.png",
  },
  {
    id: 364,
    name: "sealeo",
    display_name: "Sealeo",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/364.png",
  },
  {
    id: 365,
    name: "walrein",
    display_name: "Walrein",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/365.png",
  },
  {
    id: 366,
    name: "clamperl",
    display_name: "Clamperl",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/366.png",
  },
  {
    id: 367,
    name: "huntail",
    display_name: "Huntail",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/367.png",
  },
  {
    id: 368,
    name: "gorebyss",
    display_name: "Gorebyss",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/368.png",
  },
  {
    id: 369,
    name: "relicanth",
    display_name: "Relicanth",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/369.png",
  },
  {
    id: 370,
    name: "luvdisc",
    display_name: "Luvdisc",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/370.png",
  },
  {
    id: 371,
    name: "bagon",
    display_name: "Bagon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/371.png",
  },
  {
    id: 372,
    name: "shelgon",
    display_name: "Shelgon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/372.png",
  },
  {
    id: 373,
    name: "salamence",
    display_name: "Salamence",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/373.png",
  },
  {
    id: 10089,
    name: "salamence-mega",
    display_name: "Mega Salamence",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10089.png",
  },
  {
    id: 374,
    name: "beldum",
    display_name: "Beldum",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/374.png",
  },
  {
    id: 375,
    name: "metang",
    display_name: "Metang",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/375.png",
  },
  {
    id: 376,
    name: "metagross",
    display_name: "Metagross",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/376.png",
  },
  {
    id: 10076,
    name: "metagross-mega",
    display_name: "Mega Metagross",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10076.png",
  },
  {
    id: 377,
    name: "regirock",
    display_name: "Regirock",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/377.png",
  },
  {
    id: 378,
    name: "regice",
    display_name: "Regice",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/378.png",
  },
  {
    id: 379,
    name: "registeel",
    display_name: "Registeel",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/379.png",
  },
  {
    id: 380,
    name: "latias",
    display_name: "Latias",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/380.png",
  },
  {
    id: 10062,
    name: "latias-mega",
    display_name: "Mega Latias",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10062.png",
  },
  {
    id: 381,
    name: "latios",
    display_name: "Latios",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/381.png",
  },
  {
    id: 10063,
    name: "latios-mega",
    display_name: "Mega Latios",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10063.png",
  },
  {
    id: 382,
    name: "kyogre",
    display_name: "Kyogre",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/382.png",
  },
  {
    id: 10077,
    name: "kyogre-primal",
    display_name: "Kyogre Primal Reversion",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10077.png",
  },
  {
    id: 383,
    name: "groudon",
    display_name: "Groudon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/383.png",
  },
  {
    id: 10078,
    name: "groudon-primal",
    display_name: "Groudon Primal Reversion",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10078.png",
  },
  {
    id: 384,
    name: "rayquaza",
    display_name: "Rayquaza",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/384.png",
  },
  {
    id: 10079,
    name: "rayquaza-mega",
    display_name: "Mega Rayquaza",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10079.png",
  },
  {
    id: 385,
    name: "jirachi",
    display_name: "Jirachi",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/385.png",
  },
  {
    id: 386,
    name: "deoxys-normal",
    display_name: "Deoxys Normal Forme",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/386.png",
  },
  {
    id: 10001,
    name: "deoxys-attack",
    display_name: "Deoxys Attack Forme",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10001.png",
  },
  {
    id: 10002,
    name: "deoxys-defense",
    display_name: "Deoxys Defense Forme",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10002.png",
  },
  {
    id: 10003,
    name: "deoxys-speed",
    display_name: "Deoxys Speed Forme",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10003.png",
  },
  {
    id: 387,
    name: "turtwig",
    display_name: "Turtwig",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/387.png",
  },
  {
    id: 388,
    name: "grotle",
    display_name: "Grotle",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/388.png",
  },
  {
    id: 389,
    name: "torterra",
    display_name: "Torterra",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/389.png",
  },
  {
    id: 390,
    name: "chimchar",
    display_name: "Chimchar",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/390.png",
  },
  {
    id: 391,
    name: "monferno",
    display_name: "Monferno",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/391.png",
  },
  {
    id: 392,
    name: "infernape",
    display_name: "Infernape",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/392.png",
  },
  {
    id: 393,
    name: "piplup",
    display_name: "Piplup",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/393.png",
  },
  {
    id: 394,
    name: "prinplup",
    display_name: "Prinplup",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/394.png",
  },
  {
    id: 395,
    name: "empoleon",
    display_name: "Empoleon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/395.png",
  },
  {
    id: 396,
    name: "starly",
    display_name: "Starly",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/396.png",
  },
  {
    id: 397,
    name: "staravia",
    display_name: "Staravia",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/397.png",
  },
  {
    id: 398,
    name: "staraptor",
    display_name: "Staraptor",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/398.png",
  },
  {
    id: 399,
    name: "bidoof",
    display_name: "Bidoof",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/399.png",
  },
  {
    id: 400,
    name: "bibarel",
    display_name: "Bibarel",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/400.png",
  },
  {
    id: 401,
    name: "kricketot",
    display_name: "Kricketot",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/401.png",
  },
  {
    id: 402,
    name: "kricketune",
    display_name: "Kricketune",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/402.png",
  },
  {
    id: 403,
    name: "shinx",
    display_name: "Shinx",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/403.png",
  },
  {
    id: 404,
    name: "luxio",
    display_name: "Luxio",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/404.png",
  },
  {
    id: 405,
    name: "luxray",
    display_name: "Luxray",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/405.png",
  },
  {
    id: 406,
    name: "budew",
    display_name: "Budew",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/406.png",
  },
  {
    id: 407,
    name: "roserade",
    display_name: "Roserade",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/407.png",
  },
  {
    id: 408,
    name: "cranidos",
    display_name: "Cranidos",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/408.png",
  },
  {
    id: 409,
    name: "rampardos",
    display_name: "Rampardos",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/409.png",
  },
  {
    id: 410,
    name: "shieldon",
    display_name: "Shieldon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/410.png",
  },
  {
    id: 411,
    name: "bastiodon",
    display_name: "Bastiodon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/411.png",
  },
  {
    id: 412,
    name: "burmy",
    display_name: "Burmy",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/412.png",
  },
  {
    id: 413,
    name: "wormadam-plant",
    display_name: "Wormadam Plant Cloak",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/413.png",
  },
  {
    id: 10004,
    name: "wormadam-sandy",
    display_name: "Wormadam Sandy Cloak",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10004.png",
  },
  {
    id: 10005,
    name: "wormadam-trash",
    display_name: "Wormadam Trash Cloak",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10005.png",
  },
  {
    id: 414,
    name: "mothim",
    display_name: "Mothim",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/414.png",
  },
  {
    id: 415,
    name: "combee",
    display_name: "Combee",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/415.png",
  },
  {
    id: 416,
    name: "vespiquen",
    display_name: "Vespiquen",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/416.png",
  },
  {
    id: 417,
    name: "pachirisu",
    display_name: "Pachirisu",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/417.png",
  },
  {
    id: 418,
    name: "buizel",
    display_name: "Buizel",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/418.png",
  },
  {
    id: 419,
    name: "floatzel",
    display_name: "Floatzel",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/419.png",
  },
  {
    id: 420,
    name: "cherubi",
    display_name: "Cherubi",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/420.png",
  },
  {
    id: 421,
    name: "cherrim",
    display_name: "Cherrim",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/421.png",
  },
  {
    id: 422,
    name: "shellos",
    display_name: "Shellos",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/422.png",
  },
  {
    id: 423,
    name: "gastrodon",
    display_name: "Gastrodon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/423.png",
  },
  {
    id: 424,
    name: "ambipom",
    display_name: "Ambipom",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/424.png",
  },
  {
    id: 425,
    name: "drifloon",
    display_name: "Drifloon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/425.png",
  },
  {
    id: 426,
    name: "drifblim",
    display_name: "Drifblim",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/426.png",
  },
  {
    id: 427,
    name: "buneary",
    display_name: "Buneary",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/427.png",
  },
  {
    id: 428,
    name: "lopunny",
    display_name: "Lopunny",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/428.png",
  },
  {
    id: 10088,
    name: "lopunny-mega",
    display_name: "Mega Lopunny",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10088.png",
  },
  {
    id: 429,
    name: "mismagius",
    display_name: "Mismagius",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/429.png",
  },
  {
    id: 430,
    name: "honchkrow",
    display_name: "Honchkrow",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/430.png",
  },
  {
    id: 431,
    name: "glameow",
    display_name: "Glameow",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/431.png",
  },
  {
    id: 432,
    name: "purugly",
    display_name: "Purugly",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/432.png",
  },
  {
    id: 433,
    name: "chingling",
    display_name: "Chingling",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/433.png",
  },
  {
    id: 434,
    name: "stunky",
    display_name: "Stunky",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/434.png",
  },
  {
    id: 435,
    name: "skuntank",
    display_name: "Skuntank",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/435.png",
  },
  {
    id: 436,
    name: "bronzor",
    display_name: "Bronzor",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/436.png",
  },
  {
    id: 437,
    name: "bronzong",
    display_name: "Bronzong",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/437.png",
  },
  {
    id: 438,
    name: "bonsly",
    display_name: "Bonsly",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/438.png",
  },
  {
    id: 439,
    name: "mime-jr",
    display_name: "Mime Jr.",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/439.png",
  },
  {
    id: 440,
    name: "happiny",
    display_name: "Happiny",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/440.png",
  },
  {
    id: 441,
    name: "chatot",
    display_name: "Chatot",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/441.png",
  },
  {
    id: 442,
    name: "spiritomb",
    display_name: "Spiritomb",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/442.png",
  },
  {
    id: 443,
    name: "gible",
    display_name: "Gible",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/443.png",
  },
  {
    id: 444,
    name: "gabite",
    display_name: "Gabite",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/444.png",
  },
  {
    id: 445,
    name: "garchomp",
    display_name: "Garchomp",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/445.png",
  },
  {
    id: 10058,
    name: "garchomp-mega",
    display_name: "Mega Garchomp",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10058.png",
  },
  {
    id: 446,
    name: "munchlax",
    display_name: "Munchlax",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/446.png",
  },
  {
    id: 447,
    name: "riolu",
    display_name: "Riolu",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/447.png",
  },
  {
    id: 448,
    name: "lucario",
    display_name: "Lucario",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/448.png",
  },
  {
    id: 10059,
    name: "lucario-mega",
    display_name: "Mega Lucario",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10059.png",
  },
  {
    id: 449,
    name: "hippopotas",
    display_name: "Hippopotas",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/449.png",
  },
  {
    id: 450,
    name: "hippowdon",
    display_name: "Hippowdon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/450.png",
  },
  {
    id: 451,
    name: "skorupi",
    display_name: "Skorupi",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/451.png",
  },
  {
    id: 452,
    name: "drapion",
    display_name: "Drapion",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/452.png",
  },
  {
    id: 453,
    name: "croagunk",
    display_name: "Croagunk",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/453.png",
  },
  {
    id: 454,
    name: "toxicroak",
    display_name: "Toxicroak",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/454.png",
  },
  {
    id: 455,
    name: "carnivine",
    display_name: "Carnivine",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/455.png",
  },
  {
    id: 456,
    name: "finneon",
    display_name: "Finneon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/456.png",
  },
  {
    id: 457,
    name: "lumineon",
    display_name: "Lumineon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/457.png",
  },
  {
    id: 458,
    name: "mantyke",
    display_name: "Mantyke",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/458.png",
  },
  {
    id: 459,
    name: "snover",
    display_name: "Snover",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/459.png",
  },
  {
    id: 460,
    name: "abomasnow",
    display_name: "Abomasnow",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/460.png",
  },
  {
    id: 10060,
    name: "abomasnow-mega",
    display_name: "Mega Abomasnow",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10060.png",
  },
  {
    id: 461,
    name: "weavile",
    display_name: "Weavile",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/461.png",
  },
  {
    id: 462,
    name: "magnezone",
    display_name: "Magnezone",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/462.png",
  },
  {
    id: 463,
    name: "lickilicky",
    display_name: "Lickilicky",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/463.png",
  },
  {
    id: 464,
    name: "rhyperior",
    display_name: "Rhyperior",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/464.png",
  },
  {
    id: 465,
    name: "tangrowth",
    display_name: "Tangrowth",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/465.png",
  },
  {
    id: 466,
    name: "electivire",
    display_name: "Electivire",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/466.png",
  },
  {
    id: 467,
    name: "magmortar",
    display_name: "Magmortar",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/467.png",
  },
  {
    id: 468,
    name: "togekiss",
    display_name: "Togekiss",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/468.png",
  },
  {
    id: 469,
    name: "yanmega",
    display_name: "Yanmega",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/469.png",
  },
  {
    id: 470,
    name: "leafeon",
    display_name: "Leafeon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/470.png",
  },
  {
    id: 471,
    name: "glaceon",
    display_name: "Glaceon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/471.png",
  },
  {
    id: 472,
    name: "gliscor",
    display_name: "Gliscor",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/472.png",
  },
  {
    id: 473,
    name: "mamoswine",
    display_name: "Mamoswine",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/473.png",
  },
  {
    id: 474,
    name: "porygon-z",
    display_name: "Porygon-Z",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/474.png",
  },
  {
    id: 475,
    name: "gallade",
    display_name: "Gallade",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/475.png",
  },
  {
    id: 10068,
    name: "gallade-mega",
    display_name: "Mega Gallade",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10068.png",
  },
  {
    id: 476,
    name: "probopass",
    display_name: "Probopass",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/476.png",
  },
  {
    id: 477,
    name: "dusknoir",
    display_name: "Dusknoir",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/477.png",
  },
  {
    id: 478,
    name: "froslass",
    display_name: "Froslass",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/478.png",
  },
  {
    id: 479,
    name: "rotom",
    display_name: "Rotom",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/479.png",
  },
  {
    id: 10008,
    name: "rotom-heat",
    display_name: "Heat Rotom",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10008.png",
  },
  {
    id: 10009,
    name: "rotom-wash",
    display_name: "Wash Rotom",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10009.png",
  },
  {
    id: 10010,
    name: "rotom-frost",
    display_name: "Frost Rotom",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10010.png",
  },
  {
    id: 10011,
    name: "rotom-fan",
    display_name: "Fan Rotom",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10011.png",
  },
  {
    id: 10012,
    name: "rotom-mow",
    display_name: "Mow Rotom",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10012.png",
  },
  {
    id: 480,
    name: "uxie",
    display_name: "Uxie",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/480.png",
  },
  {
    id: 481,
    name: "mesprit",
    display_name: "Mesprit",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/481.png",
  },
  {
    id: 482,
    name: "azelf",
    display_name: "Azelf",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/482.png",
  },
  {
    id: 483,
    name: "dialga",
    display_name: "Dialga",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/483.png",
  },
  {
    id: 10245,
    name: "dialga-origin",
    display_name: "Dialga Origin Forme",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10245.png",
  },
  {
    id: 484,
    name: "palkia",
    display_name: "Palkia",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/484.png",
  },
  {
    id: 10246,
    name: "palkia-origin",
    display_name: "Palkia Origin Forme",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10246.png",
  },
  {
    id: 485,
    name: "heatran",
    display_name: "Heatran",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/485.png",
  },
  {
    id: 486,
    name: "regigigas",
    display_name: "Regigigas",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/486.png",
  },
  {
    id: 487,
    name: "giratina-altered",
    display_name: "Giratina Altered Forme",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/487.png",
  },
  {
    id: 10007,
    name: "giratina-origin",
    display_name: "Giratina Origin Forme",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10007.png",
  },
  {
    id: 488,
    name: "cresselia",
    display_name: "Cresselia",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/488.png",
  },
  {
    id: 489,
    name: "phione",
    display_name: "Phione",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/489.png",
  },
  {
    id: 490,
    name: "manaphy",
    display_name: "Manaphy",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/490.png",
  },
  {
    id: 491,
    name: "darkrai",
    display_name: "Darkrai",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/491.png",
  },
  {
    id: 492,
    name: "shaymin-land",
    display_name: "Shaymin Land Forme",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/492.png",
  },
  {
    id: 10006,
    name: "shaymin-sky",
    display_name: "Shaymin Sky Forme",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10006.png",
  },
  {
    id: 493,
    name: "arceus",
    display_name: "Arceus",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/493.png",
  },
  {
    id: 494,
    name: "victini",
    display_name: "Victini",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/494.png",
  },
  {
    id: 495,
    name: "snivy",
    display_name: "Snivy",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/495.png",
  },
  {
    id: 496,
    name: "servine",
    display_name: "Servine",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/496.png",
  },
  {
    id: 497,
    name: "serperior",
    display_name: "Serperior",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/497.png",
  },
  {
    id: 498,
    name: "tepig",
    display_name: "Tepig",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/498.png",
  },
  {
    id: 499,
    name: "pignite",
    display_name: "Pignite",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/499.png",
  },
  {
    id: 500,
    name: "emboar",
    display_name: "Emboar",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/500.png",
  },
  {
    id: 501,
    name: "oshawott",
    display_name: "Oshawott",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/501.png",
  },
  {
    id: 502,
    name: "dewott",
    display_name: "Dewott",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/502.png",
  },
  {
    id: 503,
    name: "samurott",
    display_name: "Samurott",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/503.png",
  },
  {
    id: 10236,
    name: "samurott-hisui",
    display_name: "Samurott Hisuian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10236.png",
  },
  {
    id: 504,
    name: "patrat",
    display_name: "Patrat",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/504.png",
  },
  {
    id: 505,
    name: "watchog",
    display_name: "Watchog",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/505.png",
  },
  {
    id: 506,
    name: "lillipup",
    display_name: "Lillipup",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/506.png",
  },
  {
    id: 507,
    name: "herdier",
    display_name: "Herdier",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/507.png",
  },
  {
    id: 508,
    name: "stoutland",
    display_name: "Stoutland",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/508.png",
  },
  {
    id: 509,
    name: "purrloin",
    display_name: "Purrloin",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/509.png",
  },
  {
    id: 510,
    name: "liepard",
    display_name: "Liepard",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/510.png",
  },
  {
    id: 511,
    name: "pansage",
    display_name: "Pansage",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/511.png",
  },
  {
    id: 512,
    name: "simisage",
    display_name: "Simisage",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/512.png",
  },
  {
    id: 513,
    name: "pansear",
    display_name: "Pansear",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/513.png",
  },
  {
    id: 514,
    name: "simisear",
    display_name: "Simisear",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/514.png",
  },
  {
    id: 515,
    name: "panpour",
    display_name: "Panpour",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/515.png",
  },
  {
    id: 516,
    name: "simipour",
    display_name: "Simipour",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/516.png",
  },
  {
    id: 517,
    name: "munna",
    display_name: "Munna",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/517.png",
  },
  {
    id: 518,
    name: "musharna",
    display_name: "Musharna",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/518.png",
  },
  {
    id: 519,
    name: "pidove",
    display_name: "Pidove",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/519.png",
  },
  {
    id: 520,
    name: "tranquill",
    display_name: "Tranquill",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/520.png",
  },
  {
    id: 521,
    name: "unfezant",
    display_name: "Unfezant",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/521.png",
  },
  {
    id: 522,
    name: "blitzle",
    display_name: "Blitzle",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/522.png",
  },
  {
    id: 523,
    name: "zebstrika",
    display_name: "Zebstrika",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/523.png",
  },
  {
    id: 524,
    name: "roggenrola",
    display_name: "Roggenrola",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/524.png",
  },
  {
    id: 525,
    name: "boldore",
    display_name: "Boldore",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/525.png",
  },
  {
    id: 526,
    name: "gigalith",
    display_name: "Gigalith",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/526.png",
  },
  {
    id: 527,
    name: "woobat",
    display_name: "Woobat",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/527.png",
  },
  {
    id: 528,
    name: "swoobat",
    display_name: "Swoobat",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/528.png",
  },
  {
    id: 529,
    name: "drilbur",
    display_name: "Drilbur",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/529.png",
  },
  {
    id: 530,
    name: "excadrill",
    display_name: "Excadrill",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/530.png",
  },
  {
    id: 531,
    name: "audino",
    display_name: "Audino",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/531.png",
  },
  {
    id: 10069,
    name: "audino-mega",
    display_name: "Mega Audino",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10069.png",
  },
  {
    id: 532,
    name: "timburr",
    display_name: "Timburr",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/532.png",
  },
  {
    id: 533,
    name: "gurdurr",
    display_name: "Gurdurr",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/533.png",
  },
  {
    id: 534,
    name: "conkeldurr",
    display_name: "Conkeldurr",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/534.png",
  },
  {
    id: 535,
    name: "tympole",
    display_name: "Tympole",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/535.png",
  },
  {
    id: 536,
    name: "palpitoad",
    display_name: "Palpitoad",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/536.png",
  },
  {
    id: 537,
    name: "seismitoad",
    display_name: "Seismitoad",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/537.png",
  },
  {
    id: 538,
    name: "throh",
    display_name: "Throh",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/538.png",
  },
  {
    id: 539,
    name: "sawk",
    display_name: "Sawk",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/539.png",
  },
  {
    id: 540,
    name: "sewaddle",
    display_name: "Sewaddle",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/540.png",
  },
  {
    id: 541,
    name: "swadloon",
    display_name: "Swadloon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/541.png",
  },
  {
    id: 542,
    name: "leavanny",
    display_name: "Leavanny",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/542.png",
  },
  {
    id: 543,
    name: "venipede",
    display_name: "Venipede",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/543.png",
  },
  {
    id: 544,
    name: "whirlipede",
    display_name: "Whirlipede",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/544.png",
  },
  {
    id: 545,
    name: "scolipede",
    display_name: "Scolipede",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/545.png",
  },
  {
    id: 546,
    name: "cottonee",
    display_name: "Cottonee",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/546.png",
  },
  {
    id: 547,
    name: "whimsicott",
    display_name: "Whimsicott",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/547.png",
  },
  {
    id: 548,
    name: "petilil",
    display_name: "Petilil",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/548.png",
  },
  {
    id: 549,
    name: "lilligant",
    display_name: "Lilligant",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/549.png",
  },
  {
    id: 10237,
    name: "lilligant-hisui",
    display_name: "Lilligant Hisuian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10237.png",
  },
  {
    id: 550,
    name: "basculin-red-striped",
    display_name: "Basculin Red-Striped Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/550.png",
  },
  {
    id: 10016,
    name: "basculin-blue-striped",
    display_name: "Basculin Blue-Striped Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10016.png",
  },
  {
    id: 10247,
    name: "basculin-white-striped",
    display_name: "Basculin White-Striped Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10247.png",
  },
  {
    id: 551,
    name: "sandile",
    display_name: "Sandile",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/551.png",
  },
  {
    id: 552,
    name: "krokorok",
    display_name: "Krokorok",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/552.png",
  },
  {
    id: 553,
    name: "krookodile",
    display_name: "Krookodile",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/553.png",
  },
  {
    id: 554,
    name: "darumaka",
    display_name: "Darumaka",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/554.png",
  },
  {
    id: 10176,
    name: "darumaka-galar",
    display_name: "Darumaka Galarian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10176.png",
  },
  {
    id: 555,
    name: "darmanitan-standard",
    display_name: "Darmanitan Standard Mode",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/555.png",
  },
  {
    id: 10017,
    name: "darmanitan-zen",
    display_name: "Darmanitan Zen Mode",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10017.png",
  },
  {
    id: 10177,
    name: "darmanitan-galar-standard",
    display_name: "Darmanitan Galarian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10177.png",
  },
  {
    id: 10178,
    name: "darmanitan-galar-zen",
    display_name: "Darmanitan Galarian Form Zen Mode",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10178.png",
  },
  {
    id: 556,
    name: "maractus",
    display_name: "Maractus",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/556.png",
  },
  {
    id: 557,
    name: "dwebble",
    display_name: "Dwebble",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/557.png",
  },
  {
    id: 558,
    name: "crustle",
    display_name: "Crustle",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/558.png",
  },
  {
    id: 559,
    name: "scraggy",
    display_name: "Scraggy",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/559.png",
  },
  {
    id: 560,
    name: "scrafty",
    display_name: "Scrafty",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/560.png",
  },
  {
    id: 561,
    name: "sigilyph",
    display_name: "Sigilyph",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/561.png",
  },
  {
    id: 562,
    name: "yamask",
    display_name: "Yamask",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/562.png",
  },
  {
    id: 10179,
    name: "yamask-galar",
    display_name: "Yamask Galarian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10179.png",
  },
  {
    id: 563,
    name: "cofagrigus",
    display_name: "Cofagrigus",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/563.png",
  },
  {
    id: 564,
    name: "tirtouga",
    display_name: "Tirtouga",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/564.png",
  },
  {
    id: 565,
    name: "carracosta",
    display_name: "Carracosta",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/565.png",
  },
  {
    id: 566,
    name: "archen",
    display_name: "Archen",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/566.png",
  },
  {
    id: 567,
    name: "archeops",
    display_name: "Archeops",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/567.png",
  },
  {
    id: 568,
    name: "trubbish",
    display_name: "Trubbish",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/568.png",
  },
  {
    id: 569,
    name: "garbodor",
    display_name: "Garbodor",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/569.png",
  },
  {
    id: 10207,
    name: "garbodor-gmax",
    display_name: "Garbodor Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10207.png",
  },
  {
    id: 570,
    name: "zorua",
    display_name: "Zorua",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/570.png",
  },
  {
    id: 10238,
    name: "zorua-hisui",
    display_name: "Zorua Hisuian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10238.png",
  },
  {
    id: 571,
    name: "zoroark",
    display_name: "Zoroark",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/571.png",
  },
  {
    id: 10239,
    name: "zoroark-hisui",
    display_name: "Zoroark Hisuian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10239.png",
  },
  {
    id: 572,
    name: "minccino",
    display_name: "Minccino",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/572.png",
  },
  {
    id: 573,
    name: "cinccino",
    display_name: "Cinccino",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/573.png",
  },
  {
    id: 574,
    name: "gothita",
    display_name: "Gothita",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/574.png",
  },
  {
    id: 575,
    name: "gothorita",
    display_name: "Gothorita",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/575.png",
  },
  {
    id: 576,
    name: "gothitelle",
    display_name: "Gothitelle",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/576.png",
  },
  {
    id: 577,
    name: "solosis",
    display_name: "Solosis",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/577.png",
  },
  {
    id: 578,
    name: "duosion",
    display_name: "Duosion",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/578.png",
  },
  {
    id: 579,
    name: "reuniclus",
    display_name: "Reuniclus",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/579.png",
  },
  {
    id: 580,
    name: "ducklett",
    display_name: "Ducklett",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/580.png",
  },
  {
    id: 581,
    name: "swanna",
    display_name: "Swanna",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/581.png",
  },
  {
    id: 582,
    name: "vanillite",
    display_name: "Vanillite",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/582.png",
  },
  {
    id: 583,
    name: "vanillish",
    display_name: "Vanillish",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/583.png",
  },
  {
    id: 584,
    name: "vanilluxe",
    display_name: "Vanilluxe",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/584.png",
  },
  {
    id: 585,
    name: "deerling",
    display_name: "Deerling",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/585.png",
  },
  {
    id: 586,
    name: "sawsbuck",
    display_name: "Sawsbuck",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/586.png",
  },
  {
    id: 587,
    name: "emolga",
    display_name: "Emolga",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/587.png",
  },
  {
    id: 588,
    name: "karrablast",
    display_name: "Karrablast",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/588.png",
  },
  {
    id: 589,
    name: "escavalier",
    display_name: "Escavalier",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/589.png",
  },
  {
    id: 590,
    name: "foongus",
    display_name: "Foongus",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/590.png",
  },
  {
    id: 591,
    name: "amoonguss",
    display_name: "Amoonguss",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/591.png",
  },
  {
    id: 592,
    name: "frillish",
    display_name: "Frillish",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/592.png",
  },
  {
    id: 593,
    name: "jellicent",
    display_name: "Jellicent",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/593.png",
  },
  {
    id: 594,
    name: "alomomola",
    display_name: "Alomomola",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/594.png",
  },
  {
    id: 595,
    name: "joltik",
    display_name: "Joltik",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/595.png",
  },
  {
    id: 596,
    name: "galvantula",
    display_name: "Galvantula",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/596.png",
  },
  {
    id: 597,
    name: "ferroseed",
    display_name: "Ferroseed",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/597.png",
  },
  {
    id: 598,
    name: "ferrothorn",
    display_name: "Ferrothorn",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/598.png",
  },
  {
    id: 599,
    name: "klink",
    display_name: "Klink",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/599.png",
  },
  {
    id: 600,
    name: "klang",
    display_name: "Klang",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/600.png",
  },
  {
    id: 601,
    name: "klinklang",
    display_name: "Klinklang",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/601.png",
  },
  {
    id: 602,
    name: "tynamo",
    display_name: "Tynamo",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/602.png",
  },
  {
    id: 603,
    name: "eelektrik",
    display_name: "Eelektrik",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/603.png",
  },
  {
    id: 604,
    name: "eelektross",
    display_name: "Eelektross",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/604.png",
  },
  {
    id: 605,
    name: "elgyem",
    display_name: "Elgyem",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/605.png",
  },
  {
    id: 606,
    name: "beheeyem",
    display_name: "Beheeyem",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/606.png",
  },
  {
    id: 607,
    name: "litwick",
    display_name: "Litwick",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/607.png",
  },
  {
    id: 608,
    name: "lampent",
    display_name: "Lampent",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/608.png",
  },
  {
    id: 609,
    name: "chandelure",
    display_name: "Chandelure",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/609.png",
  },
  {
    id: 610,
    name: "axew",
    display_name: "Axew",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/610.png",
  },
  {
    id: 611,
    name: "fraxure",
    display_name: "Fraxure",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/611.png",
  },
  {
    id: 612,
    name: "haxorus",
    display_name: "Haxorus",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/612.png",
  },
  {
    id: 613,
    name: "cubchoo",
    display_name: "Cubchoo",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/613.png",
  },
  {
    id: 614,
    name: "beartic",
    display_name: "Beartic",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/614.png",
  },
  {
    id: 615,
    name: "cryogonal",
    display_name: "Cryogonal",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/615.png",
  },
  {
    id: 616,
    name: "shelmet",
    display_name: "Shelmet",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/616.png",
  },
  {
    id: 617,
    name: "accelgor",
    display_name: "Accelgor",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/617.png",
  },
  {
    id: 618,
    name: "stunfisk",
    display_name: "Stunfisk",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/618.png",
  },
  {
    id: 10180,
    name: "stunfisk-galar",
    display_name: "Stunfisk Galarian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10180.png",
  },
  {
    id: 619,
    name: "mienfoo",
    display_name: "Mienfoo",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/619.png",
  },
  {
    id: 620,
    name: "mienshao",
    display_name: "Mienshao",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/620.png",
  },
  {
    id: 621,
    name: "druddigon",
    display_name: "Druddigon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/621.png",
  },
  {
    id: 622,
    name: "golett",
    display_name: "Golett",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/622.png",
  },
  {
    id: 623,
    name: "golurk",
    display_name: "Golurk",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/623.png",
  },
  {
    id: 624,
    name: "pawniard",
    display_name: "Pawniard",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/624.png",
  },
  {
    id: 625,
    name: "bisharp",
    display_name: "Bisharp",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/625.png",
  },
  {
    id: 626,
    name: "bouffalant",
    display_name: "Bouffalant",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/626.png",
  },
  {
    id: 627,
    name: "rufflet",
    display_name: "Rufflet",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/627.png",
  },
  {
    id: 628,
    name: "braviary",
    display_name: "Braviary",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/628.png",
  },
  {
    id: 10240,
    name: "braviary-hisui",
    display_name: "Braviary Hisuian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10240.png",
  },
  {
    id: 629,
    name: "vullaby",
    display_name: "Vullaby",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/629.png",
  },
  {
    id: 630,
    name: "mandibuzz",
    display_name: "Mandibuzz",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/630.png",
  },
  {
    id: 631,
    name: "heatmor",
    display_name: "Heatmor",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/631.png",
  },
  {
    id: 632,
    name: "durant",
    display_name: "Durant",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/632.png",
  },
  {
    id: 633,
    name: "deino",
    display_name: "Deino",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/633.png",
  },
  {
    id: 634,
    name: "zweilous",
    display_name: "Zweilous",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/634.png",
  },
  {
    id: 635,
    name: "hydreigon",
    display_name: "Hydreigon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/635.png",
  },
  {
    id: 636,
    name: "larvesta",
    display_name: "Larvesta",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/636.png",
  },
  {
    id: 637,
    name: "volcarona",
    display_name: "Volcarona",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/637.png",
  },
  {
    id: 638,
    name: "cobalion",
    display_name: "Cobalion",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/638.png",
  },
  {
    id: 639,
    name: "terrakion",
    display_name: "Terrakion",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/639.png",
  },
  {
    id: 640,
    name: "virizion",
    display_name: "Virizion",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/640.png",
  },
  {
    id: 641,
    name: "tornadus-incarnate",
    display_name: "Tornadus Incarnate Forme",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/641.png",
  },
  {
    id: 10019,
    name: "tornadus-therian",
    display_name: "Tornadus Therian Forme",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10019.png",
  },
  {
    id: 642,
    name: "thundurus-incarnate",
    display_name: "Thundurus Incarnate Forme",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/642.png",
  },
  {
    id: 10020,
    name: "thundurus-therian",
    display_name: "Thundurus Therian Forme",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10020.png",
  },
  {
    id: 643,
    name: "reshiram",
    display_name: "Reshiram",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/643.png",
  },
  {
    id: 644,
    name: "zekrom",
    display_name: "Zekrom",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/644.png",
  },
  {
    id: 645,
    name: "landorus-incarnate",
    display_name: "Landorus Incarnate Forme",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/645.png",
  },
  {
    id: 10021,
    name: "landorus-therian",
    display_name: "Landorus Therian Forme",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10021.png",
  },
  {
    id: 646,
    name: "kyurem",
    display_name: "Kyurem",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/646.png",
  },
  {
    id: 10022,
    name: "kyurem-black",
    display_name: "Black Kyurem",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10022.png",
  },
  {
    id: 10023,
    name: "kyurem-white",
    display_name: "White Kyurem",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10023.png",
  },
  {
    id: 647,
    name: "keldeo-ordinary",
    display_name: "Keldeo Ordinary Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/647.png",
  },
  {
    id: 10024,
    name: "keldeo-resolute",
    display_name: "Keldeo Resolute Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10024.png",
  },
  {
    id: 648,
    name: "meloetta-aria",
    display_name: "Meloetta Aria Forme",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/648.png",
  },
  {
    id: 10018,
    name: "meloetta-pirouette",
    display_name: "Meloetta Pirouette Forme",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10018.png",
  },
  {
    id: 649,
    name: "genesect",
    display_name: "Genesect",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/649.png",
  },
  {
    id: 650,
    name: "chespin",
    display_name: "Chespin",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/650.png",
  },
  {
    id: 651,
    name: "quilladin",
    display_name: "Quilladin",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/651.png",
  },
  {
    id: 652,
    name: "chesnaught",
    display_name: "Chesnaught",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/652.png",
  },
  {
    id: 653,
    name: "fennekin",
    display_name: "Fennekin",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/653.png",
  },
  {
    id: 654,
    name: "braixen",
    display_name: "Braixen",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/654.png",
  },
  {
    id: 655,
    name: "delphox",
    display_name: "Delphox",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/655.png",
  },
  {
    id: 656,
    name: "froakie",
    display_name: "Froakie",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/656.png",
  },
  {
    id: 657,
    name: "frogadier",
    display_name: "Frogadier",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/657.png",
  },
  {
    id: 658,
    name: "greninja",
    display_name: "Greninja",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/658.png",
  },
  {
    id: 10116,
    name: "greninja-battle-bond",
    display_name: "Greninja",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10116.png",
  },
  {
    id: 10117,
    name: "greninja-ash",
    display_name: "Greninja Ash-Greninja",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10117.png",
  },
  {
    id: 659,
    name: "bunnelby",
    display_name: "Bunnelby",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/659.png",
  },
  {
    id: 660,
    name: "diggersby",
    display_name: "Diggersby",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/660.png",
  },
  {
    id: 661,
    name: "fletchling",
    display_name: "Fletchling",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/661.png",
  },
  {
    id: 662,
    name: "fletchinder",
    display_name: "Fletchinder",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/662.png",
  },
  {
    id: 663,
    name: "talonflame",
    display_name: "Talonflame",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/663.png",
  },
  {
    id: 664,
    name: "scatterbug",
    display_name: "Scatterbug",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/664.png",
  },
  {
    id: 665,
    name: "spewpa",
    display_name: "Spewpa",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/665.png",
  },
  {
    id: 666,
    name: "vivillon",
    display_name: "Vivillon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/666.png",
  },
  {
    id: 667,
    name: "litleo",
    display_name: "Litleo",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/667.png",
  },
  {
    id: 668,
    name: "pyroar",
    display_name: "Pyroar",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/668.png",
  },
  {
    id: 669,
    name: "flabebe",
    display_name: "Flabébé",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/669.png",
  },
  {
    id: 670,
    name: "floette",
    display_name: "Floette",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/670.png",
  },
  {
    id: 10061,
    name: "floette-eternal",
    display_name: "Floette Eternal Flower",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10061.png",
  },
  {
    id: 671,
    name: "florges",
    display_name: "Florges",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/671.png",
  },
  {
    id: 672,
    name: "skiddo",
    display_name: "Skiddo",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/672.png",
  },
  {
    id: 673,
    name: "gogoat",
    display_name: "Gogoat",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/673.png",
  },
  {
    id: 674,
    name: "pancham",
    display_name: "Pancham",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/674.png",
  },
  {
    id: 675,
    name: "pangoro",
    display_name: "Pangoro",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/675.png",
  },
  {
    id: 676,
    name: "furfrou",
    display_name: "Furfrou",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/676.png",
  },
  {
    id: 677,
    name: "espurr",
    display_name: "Espurr",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/677.png",
  },
  {
    id: 678,
    name: "meowstic-male",
    display_name: "Meowstic Male",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/678.png",
  },
  {
    id: 10025,
    name: "meowstic-female",
    display_name: "Meowstic Female",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10025.png",
  },
  {
    id: 679,
    name: "honedge",
    display_name: "Honedge",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/679.png",
  },
  {
    id: 680,
    name: "doublade",
    display_name: "Doublade",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/680.png",
  },
  {
    id: 681,
    name: "aegislash-shield",
    display_name: "Aegislash Shield Forme",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/681.png",
  },
  {
    id: 10026,
    name: "aegislash-blade",
    display_name: "Aegislash Blade Forme",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10026.png",
  },
  {
    id: 682,
    name: "spritzee",
    display_name: "Spritzee",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/682.png",
  },
  {
    id: 683,
    name: "aromatisse",
    display_name: "Aromatisse",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/683.png",
  },
  {
    id: 684,
    name: "swirlix",
    display_name: "Swirlix",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/684.png",
  },
  {
    id: 685,
    name: "slurpuff",
    display_name: "Slurpuff",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/685.png",
  },
  {
    id: 686,
    name: "inkay",
    display_name: "Inkay",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/686.png",
  },
  {
    id: 687,
    name: "malamar",
    display_name: "Malamar",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/687.png",
  },
  {
    id: 688,
    name: "binacle",
    display_name: "Binacle",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/688.png",
  },
  {
    id: 689,
    name: "barbaracle",
    display_name: "Barbaracle",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/689.png",
  },
  {
    id: 690,
    name: "skrelp",
    display_name: "Skrelp",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/690.png",
  },
  {
    id: 691,
    name: "dragalge",
    display_name: "Dragalge",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/691.png",
  },
  {
    id: 692,
    name: "clauncher",
    display_name: "Clauncher",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/692.png",
  },
  {
    id: 693,
    name: "clawitzer",
    display_name: "Clawitzer",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/693.png",
  },
  {
    id: 694,
    name: "helioptile",
    display_name: "Helioptile",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/694.png",
  },
  {
    id: 695,
    name: "heliolisk",
    display_name: "Heliolisk",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/695.png",
  },
  {
    id: 696,
    name: "tyrunt",
    display_name: "Tyrunt",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/696.png",
  },
  {
    id: 697,
    name: "tyrantrum",
    display_name: "Tyrantrum",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/697.png",
  },
  {
    id: 698,
    name: "amaura",
    display_name: "Amaura",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/698.png",
  },
  {
    id: 699,
    name: "aurorus",
    display_name: "Aurorus",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/699.png",
  },
  {
    id: 700,
    name: "sylveon",
    display_name: "Sylveon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/700.png",
  },
  {
    id: 701,
    name: "hawlucha",
    display_name: "Hawlucha",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/701.png",
  },
  {
    id: 702,
    name: "dedenne",
    display_name: "Dedenne",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/702.png",
  },
  {
    id: 703,
    name: "carbink",
    display_name: "Carbink",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/703.png",
  },
  {
    id: 704,
    name: "goomy",
    display_name: "Goomy",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/704.png",
  },
  {
    id: 705,
    name: "sliggoo",
    display_name: "Sliggoo",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/705.png",
  },
  {
    id: 10241,
    name: "sliggoo-hisui",
    display_name: "Sliggoo Hisuian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10241.png",
  },
  {
    id: 706,
    name: "goodra",
    display_name: "Goodra",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/706.png",
  },
  {
    id: 10242,
    name: "goodra-hisui",
    display_name: "Goodra Hisuian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10242.png",
  },
  {
    id: 707,
    name: "klefki",
    display_name: "Klefki",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/707.png",
  },
  {
    id: 708,
    name: "phantump",
    display_name: "Phantump",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/708.png",
  },
  {
    id: 709,
    name: "trevenant",
    display_name: "Trevenant",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/709.png",
  },
  {
    id: 710,
    name: "pumpkaboo-average",
    display_name: "Pumpkaboo Average Size",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/710.png",
  },
  {
    id: 10027,
    name: "pumpkaboo-small",
    display_name: "Pumpkaboo Small Size",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10027.png",
  },
  {
    id: 10028,
    name: "pumpkaboo-large",
    display_name: "Pumpkaboo Large Size",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10028.png",
  },
  {
    id: 10029,
    name: "pumpkaboo-super",
    display_name: "Pumpkaboo Super Size",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10029.png",
  },
  {
    id: 711,
    name: "gourgeist-average",
    display_name: "Gourgeist Average Size",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/711.png",
  },
  {
    id: 10030,
    name: "gourgeist-small",
    display_name: "Gourgeist Small Size",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10030.png",
  },
  {
    id: 10031,
    name: "gourgeist-large",
    display_name: "Gourgeist Large Size",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10031.png",
  },
  {
    id: 10032,
    name: "gourgeist-super",
    display_name: "Gourgeist Super Size",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10032.png",
  },
  {
    id: 712,
    name: "bergmite",
    display_name: "Bergmite",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/712.png",
  },
  {
    id: 713,
    name: "avalugg",
    display_name: "Avalugg",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/713.png",
  },
  {
    id: 10243,
    name: "avalugg-hisui",
    display_name: "Avalugg Hisuian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10243.png",
  },
  {
    id: 714,
    name: "noibat",
    display_name: "Noibat",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/714.png",
  },
  {
    id: 715,
    name: "noivern",
    display_name: "Noivern",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/715.png",
  },
  {
    id: 716,
    name: "xerneas",
    display_name: "Xerneas",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/716.png",
  },
  {
    id: 717,
    name: "yveltal",
    display_name: "Yveltal",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/717.png",
  },
  {
    id: 718,
    name: "zygarde-50",
    display_name: "Zygarde 50% Forme",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/718.png",
  },
  {
    id: 10118,
    name: "zygarde-10-power-construct",
    display_name: "Zygarde 10% Forme",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10118.png",
  },
  {
    id: 10119,
    name: "zygarde-50-power-construct",
    display_name: "Zygarde 50% Forme",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10119.png",
  },
  {
    id: 10120,
    name: "zygarde-complete",
    display_name: "Zygarde Complete Forme",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10120.png",
  },
  {
    id: 10181,
    name: "zygarde-10",
    display_name: "Zygarde 10% Forme",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10181.png",
  },
  {
    id: 719,
    name: "diancie",
    display_name: "Diancie",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/719.png",
  },
  {
    id: 10075,
    name: "diancie-mega",
    display_name: "Mega Diancie",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10075.png",
  },
  {
    id: 720,
    name: "hoopa",
    display_name: "Hoopa Confined",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/720.png",
  },
  {
    id: 10086,
    name: "hoopa-unbound",
    display_name: "Hoopa Unbound",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10086.png",
  },
  {
    id: 721,
    name: "volcanion",
    display_name: "Volcanion",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/721.png",
  },
  {
    id: 722,
    name: "rowlet",
    display_name: "Rowlet",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/722.png",
  },
  {
    id: 723,
    name: "dartrix",
    display_name: "Dartrix",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/723.png",
  },
  {
    id: 724,
    name: "decidueye",
    display_name: "Decidueye",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/724.png",
  },
  {
    id: 10244,
    name: "decidueye-hisui",
    display_name: "Decidueye Hisuian Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10244.png",
  },
  {
    id: 725,
    name: "litten",
    display_name: "Litten",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/725.png",
  },
  {
    id: 726,
    name: "torracat",
    display_name: "Torracat",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/726.png",
  },
  {
    id: 727,
    name: "incineroar",
    display_name: "Incineroar",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/727.png",
  },
  {
    id: 728,
    name: "popplio",
    display_name: "Popplio",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/728.png",
  },
  {
    id: 729,
    name: "brionne",
    display_name: "Brionne",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/729.png",
  },
  {
    id: 730,
    name: "primarina",
    display_name: "Primarina",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/730.png",
  },
  {
    id: 731,
    name: "pikipek",
    display_name: "Pikipek",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/731.png",
  },
  {
    id: 732,
    name: "trumbeak",
    display_name: "Trumbeak",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/732.png",
  },
  {
    id: 733,
    name: "toucannon",
    display_name: "Toucannon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/733.png",
  },
  {
    id: 734,
    name: "yungoos",
    display_name: "Yungoos",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/734.png",
  },
  {
    id: 735,
    name: "gumshoos",
    display_name: "Gumshoos",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/735.png",
  },
  {
    id: 10121,
    name: "gumshoos-totem",
    display_name: "Gumshoos",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10121.png",
  },
  {
    id: 736,
    name: "grubbin",
    display_name: "Grubbin",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/736.png",
  },
  {
    id: 737,
    name: "charjabug",
    display_name: "Charjabug",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/737.png",
  },
  {
    id: 738,
    name: "vikavolt",
    display_name: "Vikavolt",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/738.png",
  },
  {
    id: 10122,
    name: "vikavolt-totem",
    display_name: "Vikavolt",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10122.png",
  },
  {
    id: 739,
    name: "crabrawler",
    display_name: "Crabrawler",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/739.png",
  },
  {
    id: 740,
    name: "crabominable",
    display_name: "Crabominable",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/740.png",
  },
  {
    id: 741,
    name: "oricorio-baile",
    display_name: "Oricorio Baile Style",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/741.png",
  },
  {
    id: 10123,
    name: "oricorio-pom-pom",
    display_name: "Oricorio Pom-Pom Style",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10123.png",
  },
  {
    id: 10124,
    name: "oricorio-pau",
    display_name: "Oricorio Pa’u Style",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10124.png",
  },
  {
    id: 10125,
    name: "oricorio-sensu",
    display_name: "Oricorio Sensu Style",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10125.png",
  },
  {
    id: 742,
    name: "cutiefly",
    display_name: "Cutiefly",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/742.png",
  },
  {
    id: 743,
    name: "ribombee",
    display_name: "Ribombee",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/743.png",
  },
  {
    id: 10150,
    name: "ribombee-totem",
    display_name: "Ribombee",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10150.png",
  },
  {
    id: 744,
    name: "rockruff",
    display_name: "Rockruff",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/744.png",
  },
  {
    id: 10151,
    name: "rockruff-own-tempo",
    display_name: "Rockruff",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10151.png",
  },
  {
    id: 745,
    name: "lycanroc-midday",
    display_name: "Lycanroc Midday Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/745.png",
  },
  {
    id: 10126,
    name: "lycanroc-midnight",
    display_name: "Lycanroc Midnight Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10126.png",
  },
  {
    id: 10152,
    name: "lycanroc-dusk",
    display_name: "Lycanroc Dusk Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10152.png",
  },
  {
    id: 746,
    name: "wishiwashi-solo",
    display_name: "Wishiwashi Solo Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/746.png",
  },
  {
    id: 10127,
    name: "wishiwashi-school",
    display_name: "Wishiwashi School Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10127.png",
  },
  {
    id: 747,
    name: "mareanie",
    display_name: "Mareanie",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/747.png",
  },
  {
    id: 748,
    name: "toxapex",
    display_name: "Toxapex",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/748.png",
  },
  {
    id: 749,
    name: "mudbray",
    display_name: "Mudbray",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/749.png",
  },
  {
    id: 750,
    name: "mudsdale",
    display_name: "Mudsdale",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/750.png",
  },
  {
    id: 751,
    name: "dewpider",
    display_name: "Dewpider",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/751.png",
  },
  {
    id: 752,
    name: "araquanid",
    display_name: "Araquanid",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/752.png",
  },
  {
    id: 10153,
    name: "araquanid-totem",
    display_name: "Araquanid",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10153.png",
  },
  {
    id: 753,
    name: "fomantis",
    display_name: "Fomantis",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/753.png",
  },
  {
    id: 754,
    name: "lurantis",
    display_name: "Lurantis",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/754.png",
  },
  {
    id: 10128,
    name: "lurantis-totem",
    display_name: "Lurantis",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10128.png",
  },
  {
    id: 755,
    name: "morelull",
    display_name: "Morelull",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/755.png",
  },
  {
    id: 756,
    name: "shiinotic",
    display_name: "Shiinotic",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/756.png",
  },
  {
    id: 757,
    name: "salandit",
    display_name: "Salandit",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/757.png",
  },
  {
    id: 758,
    name: "salazzle",
    display_name: "Salazzle",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/758.png",
  },
  {
    id: 10129,
    name: "salazzle-totem",
    display_name: "Salazzle",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10129.png",
  },
  {
    id: 759,
    name: "stufful",
    display_name: "Stufful",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/759.png",
  },
  {
    id: 760,
    name: "bewear",
    display_name: "Bewear",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/760.png",
  },
  {
    id: 761,
    name: "bounsweet",
    display_name: "Bounsweet",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/761.png",
  },
  {
    id: 762,
    name: "steenee",
    display_name: "Steenee",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/762.png",
  },
  {
    id: 763,
    name: "tsareena",
    display_name: "Tsareena",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/763.png",
  },
  {
    id: 764,
    name: "comfey",
    display_name: "Comfey",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/764.png",
  },
  {
    id: 765,
    name: "oranguru",
    display_name: "Oranguru",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/765.png",
  },
  {
    id: 766,
    name: "passimian",
    display_name: "Passimian",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/766.png",
  },
  {
    id: 767,
    name: "wimpod",
    display_name: "Wimpod",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/767.png",
  },
  {
    id: 768,
    name: "golisopod",
    display_name: "Golisopod",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/768.png",
  },
  {
    id: 769,
    name: "sandygast",
    display_name: "Sandygast",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/769.png",
  },
  {
    id: 770,
    name: "palossand",
    display_name: "Palossand",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/770.png",
  },
  {
    id: 771,
    name: "pyukumuku",
    display_name: "Pyukumuku",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/771.png",
  },
  {
    id: 772,
    name: "type-null",
    display_name: "Type: Null",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/772.png",
  },
  {
    id: 773,
    name: "silvally",
    display_name: "Silvally",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/773.png",
  },
  {
    id: 774,
    name: "minior-red-meteor",
    display_name: "Minior Meteor Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/774.png",
  },
  {
    id: 10130,
    name: "minior-orange-meteor",
    display_name: "Minior Meteor Form",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10130.png",
  },
  {
    id: 10131,
    name: "minior-yellow-meteor",
    display_name: "Minior Meteor Form",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10131.png",
  },
  {
    id: 10132,
    name: "minior-green-meteor",
    display_name: "Minior Meteor Form",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10132.png",
  },
  {
    id: 10133,
    name: "minior-blue-meteor",
    display_name: "Minior Meteor Form",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10133.png",
  },
  {
    id: 10134,
    name: "minior-indigo-meteor",
    display_name: "Minior Meteor Form",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10134.png",
  },
  {
    id: 10135,
    name: "minior-violet-meteor",
    display_name: "Minior Meteor Form",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10135.png",
  },
  {
    id: 10136,
    name: "minior-red",
    display_name: "Minior Red Core",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10136.png",
  },
  {
    id: 10137,
    name: "minior-orange",
    display_name: "Minior Orange Core",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10137.png",
  },
  {
    id: 10138,
    name: "minior-yellow",
    display_name: "Minior Yellow Core",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10138.png",
  },
  {
    id: 10139,
    name: "minior-green",
    display_name: "Minior Green Core",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10139.png",
  },
  {
    id: 10140,
    name: "minior-blue",
    display_name: "Minior Blue Core",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10140.png",
  },
  {
    id: 10141,
    name: "minior-indigo",
    display_name: "Minior Indigo Core",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10141.png",
  },
  {
    id: 10142,
    name: "minior-violet",
    display_name: "Minior Violet Core",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10142.png",
  },
  {
    id: 775,
    name: "komala",
    display_name: "Komala",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/775.png",
  },
  {
    id: 776,
    name: "turtonator",
    display_name: "Turtonator",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/776.png",
  },
  {
    id: 777,
    name: "togedemaru",
    display_name: "Togedemaru",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/777.png",
  },
  {
    id: 10154,
    name: "togedemaru-totem",
    display_name: "Togedemaru",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10154.png",
  },
  {
    id: 778,
    name: "mimikyu-disguised",
    display_name: "Mimikyu Disguised Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/778.png",
  },
  {
    id: 10143,
    name: "mimikyu-busted",
    display_name: "Mimikyu Busted Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/home/10143.png",
  },
  {
    id: 10144,
    name: "mimikyu-totem-disguised",
    display_name: "Mimikyu",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10144.png",
  },
  {
    id: 10145,
    name: "mimikyu-totem-busted",
    display_name: "Mimikyu",
    hidden: true,
    img_url: null,
  },
  {
    id: 779,
    name: "bruxish",
    display_name: "Bruxish",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/779.png",
  },
  {
    id: 780,
    name: "drampa",
    display_name: "Drampa",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/780.png",
  },
  {
    id: 781,
    name: "dhelmise",
    display_name: "Dhelmise",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/781.png",
  },
  {
    id: 782,
    name: "jangmo-o",
    display_name: "Jangmo-o",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/782.png",
  },
  {
    id: 783,
    name: "hakamo-o",
    display_name: "Hakamo-o",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/783.png",
  },
  {
    id: 784,
    name: "kommo-o",
    display_name: "Kommo-o",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/784.png",
  },
  {
    id: 10146,
    name: "kommo-o-totem",
    display_name: "Kommo-o",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10146.png",
  },
  {
    id: 785,
    name: "tapu-koko",
    display_name: "Tapu Koko",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/785.png",
  },
  {
    id: 786,
    name: "tapu-lele",
    display_name: "Tapu Lele",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/786.png",
  },
  {
    id: 787,
    name: "tapu-bulu",
    display_name: "Tapu Bulu",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/787.png",
  },
  {
    id: 788,
    name: "tapu-fini",
    display_name: "Tapu Fini",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/788.png",
  },
  {
    id: 789,
    name: "cosmog",
    display_name: "Cosmog",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/789.png",
  },
  {
    id: 790,
    name: "cosmoem",
    display_name: "Cosmoem",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/790.png",
  },
  {
    id: 791,
    name: "solgaleo",
    display_name: "Solgaleo",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/791.png",
  },
  {
    id: 792,
    name: "lunala",
    display_name: "Lunala",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/792.png",
  },
  {
    id: 793,
    name: "nihilego",
    display_name: "Nihilego",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/793.png",
  },
  {
    id: 794,
    name: "buzzwole",
    display_name: "Buzzwole",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/794.png",
  },
  {
    id: 795,
    name: "pheromosa",
    display_name: "Pheromosa",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/795.png",
  },
  {
    id: 796,
    name: "xurkitree",
    display_name: "Xurkitree",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/796.png",
  },
  {
    id: 797,
    name: "celesteela",
    display_name: "Celesteela",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/797.png",
  },
  {
    id: 798,
    name: "kartana",
    display_name: "Kartana",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/798.png",
  },
  {
    id: 799,
    name: "guzzlord",
    display_name: "Guzzlord",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/799.png",
  },
  {
    id: 800,
    name: "necrozma",
    display_name: "Necrozma",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/800.png",
  },
  {
    id: 10155,
    name: "necrozma-dusk",
    display_name: "Necrozma Dusk Mane",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10155.png",
  },
  {
    id: 10156,
    name: "necrozma-dawn",
    display_name: "Necrozma Dawn Wings",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10156.png",
  },
  {
    id: 10157,
    name: "necrozma-ultra",
    display_name: "Ultra Necrozma",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10157.png",
  },
  {
    id: 801,
    name: "magearna",
    display_name: "Magearna",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/801.png",
  },
  {
    id: 10147,
    name: "magearna-original",
    display_name: "Magearna Original Color",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10147.png",
  },
  {
    id: 802,
    name: "marshadow",
    display_name: "Marshadow",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/802.png",
  },
  {
    id: 803,
    name: "poipole",
    display_name: "Poipole",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/803.png",
  },
  {
    id: 804,
    name: "naganadel",
    display_name: "Naganadel",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/804.png",
  },
  {
    id: 805,
    name: "stakataka",
    display_name: "Stakataka",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/805.png",
  },
  {
    id: 806,
    name: "blacephalon",
    display_name: "Blacephalon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/806.png",
  },
  {
    id: 807,
    name: "zeraora",
    display_name: "Zeraora",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/807.png",
  },
  {
    id: 808,
    name: "meltan",
    display_name: "Meltan",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/808.png",
  },
  {
    id: 809,
    name: "melmetal",
    display_name: "Melmetal",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/809.png",
  },
  {
    id: 10208,
    name: "melmetal-gmax",
    display_name: "Melmetal Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10208.png",
  },
  {
    id: 810,
    name: "grookey",
    display_name: "Grookey",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/810.png",
  },
  {
    id: 811,
    name: "thwackey",
    display_name: "Thwackey",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/811.png",
  },
  {
    id: 812,
    name: "rillaboom",
    display_name: "Rillaboom",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/812.png",
  },
  {
    id: 10209,
    name: "rillaboom-gmax",
    display_name: "Rillaboom Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10209.png",
  },
  {
    id: 813,
    name: "scorbunny",
    display_name: "Scorbunny",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/813.png",
  },
  {
    id: 814,
    name: "raboot",
    display_name: "Raboot",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/814.png",
  },
  {
    id: 815,
    name: "cinderace",
    display_name: "Cinderace",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/815.png",
  },
  {
    id: 10210,
    name: "cinderace-gmax",
    display_name: "Cinderace Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10210.png",
  },
  {
    id: 816,
    name: "sobble",
    display_name: "Sobble",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/816.png",
  },
  {
    id: 817,
    name: "drizzile",
    display_name: "Drizzile",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/817.png",
  },
  {
    id: 818,
    name: "inteleon",
    display_name: "Inteleon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/818.png",
  },
  {
    id: 10211,
    name: "inteleon-gmax",
    display_name: "Inteleon Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10211.png",
  },
  {
    id: 819,
    name: "skwovet",
    display_name: "Skwovet",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/819.png",
  },
  {
    id: 820,
    name: "greedent",
    display_name: "Greedent",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/820.png",
  },
  {
    id: 821,
    name: "rookidee",
    display_name: "Rookidee",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/821.png",
  },
  {
    id: 822,
    name: "corvisquire",
    display_name: "Corvisquire",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/822.png",
  },
  {
    id: 823,
    name: "corviknight",
    display_name: "Corviknight",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/823.png",
  },
  {
    id: 10212,
    name: "corviknight-gmax",
    display_name: "Corviknight Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10212.png",
  },
  {
    id: 824,
    name: "blipbug",
    display_name: "Blipbug",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/824.png",
  },
  {
    id: 825,
    name: "dottler",
    display_name: "Dottler",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/825.png",
  },
  {
    id: 826,
    name: "orbeetle",
    display_name: "Orbeetle",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/826.png",
  },
  {
    id: 10213,
    name: "orbeetle-gmax",
    display_name: "Orbeetle Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10213.png",
  },
  {
    id: 827,
    name: "nickit",
    display_name: "Nickit",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/827.png",
  },
  {
    id: 828,
    name: "thievul",
    display_name: "Thievul",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/828.png",
  },
  {
    id: 829,
    name: "gossifleur",
    display_name: "Gossifleur",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/829.png",
  },
  {
    id: 830,
    name: "eldegoss",
    display_name: "Eldegoss",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/830.png",
  },
  {
    id: 831,
    name: "wooloo",
    display_name: "Wooloo",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/831.png",
  },
  {
    id: 832,
    name: "dubwool",
    display_name: "Dubwool",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/832.png",
  },
  {
    id: 833,
    name: "chewtle",
    display_name: "Chewtle",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/833.png",
  },
  {
    id: 834,
    name: "drednaw",
    display_name: "Drednaw",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/834.png",
  },
  {
    id: 10214,
    name: "drednaw-gmax",
    display_name: "Drednaw Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10214.png",
  },
  {
    id: 835,
    name: "yamper",
    display_name: "Yamper",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/835.png",
  },
  {
    id: 836,
    name: "boltund",
    display_name: "Boltund",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/836.png",
  },
  {
    id: 837,
    name: "rolycoly",
    display_name: "Rolycoly",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/837.png",
  },
  {
    id: 838,
    name: "carkol",
    display_name: "Carkol",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/838.png",
  },
  {
    id: 839,
    name: "coalossal",
    display_name: "Coalossal",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/839.png",
  },
  {
    id: 10215,
    name: "coalossal-gmax",
    display_name: "Coalossal Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10215.png",
  },
  {
    id: 840,
    name: "applin",
    display_name: "Applin",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/840.png",
  },
  {
    id: 841,
    name: "flapple",
    display_name: "Flapple",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/841.png",
  },
  {
    id: 10216,
    name: "flapple-gmax",
    display_name: "Flapple Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10216.png",
  },
  {
    id: 842,
    name: "appletun",
    display_name: "Appletun",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/842.png",
  },
  {
    id: 10217,
    name: "appletun-gmax",
    display_name: "Appletun Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10217.png",
  },
  {
    id: 843,
    name: "silicobra",
    display_name: "Silicobra",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/843.png",
  },
  {
    id: 844,
    name: "sandaconda",
    display_name: "Sandaconda",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/844.png",
  },
  {
    id: 10218,
    name: "sandaconda-gmax",
    display_name: "Sandaconda Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10218.png",
  },
  {
    id: 845,
    name: "cramorant",
    display_name: "Cramorant",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/845.png",
  },
  {
    id: 10182,
    name: "cramorant-gulping",
    display_name: "Cramorant Gulping Form",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10182.png",
  },
  {
    id: 10183,
    name: "cramorant-gorging",
    display_name: "Cramorant Gorging Form",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10183.png",
  },
  {
    id: 846,
    name: "arrokuda",
    display_name: "Arrokuda",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/846.png",
  },
  {
    id: 847,
    name: "barraskewda",
    display_name: "Barraskewda",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/847.png",
  },
  {
    id: 848,
    name: "toxel",
    display_name: "Toxel",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/848.png",
  },
  {
    id: 849,
    name: "toxtricity-amped",
    display_name: "Toxtricity Amped Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/849.png",
  },
  {
    id: 10184,
    name: "toxtricity-low-key",
    display_name: "Toxtricity Low Key Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10184.png",
  },
  {
    id: 10219,
    name: "toxtricity-amped-gmax",
    display_name: "Toxtricity Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10219.png",
  },
  {
    id: 10228,
    name: "toxtricity-low-key-gmax",
    display_name: "Toxtricity Gigantamax Form",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10228.png",
  },
  {
    id: 850,
    name: "sizzlipede",
    display_name: "Sizzlipede",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/850.png",
  },
  {
    id: 851,
    name: "centiskorch",
    display_name: "Centiskorch",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/851.png",
  },
  {
    id: 10220,
    name: "centiskorch-gmax",
    display_name: "Centiskorch Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10220.png",
  },
  {
    id: 852,
    name: "clobbopus",
    display_name: "Clobbopus",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/852.png",
  },
  {
    id: 853,
    name: "grapploct",
    display_name: "Grapploct",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/853.png",
  },
  {
    id: 854,
    name: "sinistea",
    display_name: "Sinistea",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/854.png",
  },
  {
    id: 855,
    name: "polteageist",
    display_name: "Polteageist",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/855.png",
  },
  {
    id: 856,
    name: "hatenna",
    display_name: "Hatenna",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/856.png",
  },
  {
    id: 857,
    name: "hattrem",
    display_name: "Hattrem",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/857.png",
  },
  {
    id: 858,
    name: "hatterene",
    display_name: "Hatterene",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/858.png",
  },
  {
    id: 10221,
    name: "hatterene-gmax",
    display_name: "Hatterene Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10221.png",
  },
  {
    id: 859,
    name: "impidimp",
    display_name: "Impidimp",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/859.png",
  },
  {
    id: 860,
    name: "morgrem",
    display_name: "Morgrem",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/860.png",
  },
  {
    id: 861,
    name: "grimmsnarl",
    display_name: "Grimmsnarl",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/861.png",
  },
  {
    id: 10222,
    name: "grimmsnarl-gmax",
    display_name: "Grimmsnarl Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10222.png",
  },
  {
    id: 862,
    name: "obstagoon",
    display_name: "Obstagoon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/862.png",
  },
  {
    id: 863,
    name: "perrserker",
    display_name: "Perrserker",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/863.png",
  },
  {
    id: 864,
    name: "cursola",
    display_name: "Cursola",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/864.png",
  },
  {
    id: 865,
    name: "sirfetchd",
    display_name: "Sirfetch’d",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/865.png",
  },
  {
    id: 866,
    name: "mr-rime",
    display_name: "Mr. Rime",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/866.png",
  },
  {
    id: 867,
    name: "runerigus",
    display_name: "Runerigus",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/867.png",
  },
  {
    id: 868,
    name: "milcery",
    display_name: "Milcery",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/868.png",
  },
  {
    id: 869,
    name: "alcremie",
    display_name: "Alcremie",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/869.png",
  },
  {
    id: 10223,
    name: "alcremie-gmax",
    display_name: "Alcremie Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10223.png",
  },
  {
    id: 870,
    name: "falinks",
    display_name: "Falinks",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/870.png",
  },
  {
    id: 871,
    name: "pincurchin",
    display_name: "Pincurchin",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/871.png",
  },
  {
    id: 872,
    name: "snom",
    display_name: "Snom",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/872.png",
  },
  {
    id: 873,
    name: "frosmoth",
    display_name: "Frosmoth",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/873.png",
  },
  {
    id: 874,
    name: "stonjourner",
    display_name: "Stonjourner",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/874.png",
  },
  {
    id: 875,
    name: "eiscue-ice",
    display_name: "Eiscue Ice Face",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/875.png",
  },
  {
    id: 10185,
    name: "eiscue-noice",
    display_name: "Eiscue Noice Face",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10185.png",
  },
  {
    id: 876,
    name: "indeedee-male",
    display_name: "Indeedee Male",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/876.png",
  },
  {
    id: 10186,
    name: "indeedee-female",
    display_name: "Indeedee Female",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10186.png",
  },
  {
    id: 877,
    name: "morpeko-full-belly",
    display_name: "Morpeko Full Belly Mode",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/877.png",
  },
  {
    id: 10187,
    name: "morpeko-hangry",
    display_name: "Morpeko Hangry Mode",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10187.png",
  },
  {
    id: 878,
    name: "cufant",
    display_name: "Cufant",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/878.png",
  },
  {
    id: 879,
    name: "copperajah",
    display_name: "Copperajah",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/879.png",
  },
  {
    id: 10224,
    name: "copperajah-gmax",
    display_name: "Copperajah Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10224.png",
  },
  {
    id: 880,
    name: "dracozolt",
    display_name: "Dracozolt",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/880.png",
  },
  {
    id: 881,
    name: "arctozolt",
    display_name: "Arctozolt",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/881.png",
  },
  {
    id: 882,
    name: "dracovish",
    display_name: "Dracovish",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/882.png",
  },
  {
    id: 883,
    name: "arctovish",
    display_name: "Arctovish",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/883.png",
  },
  {
    id: 884,
    name: "duraludon",
    display_name: "Duraludon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/884.png",
  },
  {
    id: 10225,
    name: "duraludon-gmax",
    display_name: "Duraludon Gigantamax Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10225.png",
  },
  {
    id: 885,
    name: "dreepy",
    display_name: "Dreepy",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/885.png",
  },
  {
    id: 886,
    name: "drakloak",
    display_name: "Drakloak",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/886.png",
  },
  {
    id: 887,
    name: "dragapult",
    display_name: "Dragapult",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/887.png",
  },
  {
    id: 888,
    name: "zacian",
    display_name: "Zacian Hero of Many Battles",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/888.png",
  },
  {
    id: 10188,
    name: "zacian-crowned",
    display_name: "Zacian Crowned Sword",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10188.png",
  },
  {
    id: 889,
    name: "zamazenta",
    display_name: "Zamazenta Hero of Many Battles",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/889.png",
  },
  {
    id: 10189,
    name: "zamazenta-crowned",
    display_name: "Zamazenta Crowned Shield",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10189.png",
  },
  {
    id: 890,
    name: "eternatus",
    display_name: "Eternatus",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/890.png",
  },
  {
    id: 10190,
    name: "eternatus-eternamax",
    display_name: "Eternatus Eternamax",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10190.png",
  },
  {
    id: 891,
    name: "kubfu",
    display_name: "Kubfu",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/891.png",
  },
  {
    id: 892,
    name: "urshifu-single-strike",
    display_name: "Urshifu Single Strike",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/892.png",
  },
  {
    id: 10191,
    name: "urshifu-rapid-strike",
    display_name: "Urshifu Rapid Strike",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10191.png",
  },
  {
    id: 10226,
    name: "urshifu-single-strike-gmax",
    display_name: "Urshifu Gigantamax (Single Strike)",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10226.png",
  },
  {
    id: 10227,
    name: "urshifu-rapid-strike-gmax",
    display_name: "Urshifu Gigantamax (Rapid Strike)",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10227.png",
  },
  {
    id: 893,
    name: "zarude",
    display_name: "Zarude",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/893.png",
  },
  {
    id: 10192,
    name: "zarude-dada",
    display_name: "Zarude Dada",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10192.png",
  },
  {
    id: 894,
    name: "regieleki",
    display_name: "Regieleki",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/894.png",
  },
  {
    id: 895,
    name: "regidrago",
    display_name: "Regidrago",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/895.png",
  },
  {
    id: 896,
    name: "glastrier",
    display_name: "Glastrier",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/896.png",
  },
  {
    id: 897,
    name: "spectrier",
    display_name: "Spectrier",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/897.png",
  },
  {
    id: 898,
    name: "calyrex",
    display_name: "Calyrex",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/898.png",
  },
  {
    id: 10193,
    name: "calyrex-ice",
    display_name: "Calyrex Ice Rider",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10193.png",
  },
  {
    id: 10194,
    name: "calyrex-shadow",
    display_name: "Calyrex Shadow Rider",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10194.png",
  },
  {
    id: 899,
    name: "wyrdeer",
    display_name: "Wyrdeer",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/899.png",
  },
  {
    id: 900,
    name: "kleavor",
    display_name: "Kleavor",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/900.png",
  },
  {
    id: 901,
    name: "ursaluna",
    display_name: "Ursaluna",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/901.png",
  },
  {
    id: 10272,
    name: "ursaluna-bloodmoon",
    display_name: "Ursaluna Bloodmoon",
    hidden: false,
    img_url:
      "https://archives.bulbagarden.net/media/upload/archive/8/85/20231207160123%210901Ursaluna-Bloodmoon.png",
  },
  {
    id: 902,
    name: "basculegion-male",
    display_name: "Basculegion Male",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/902.png",
  },
  {
    id: 10248,
    name: "basculegion-female",
    display_name: "Basculegion Female",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10248.png",
  },
  {
    id: 903,
    name: "sneasler",
    display_name: "Sneasler",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/903.png",
  },
  {
    id: 904,
    name: "overqwil",
    display_name: "Overqwil",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/904.png",
  },
  {
    id: 905,
    name: "enamorus-incarnate",
    display_name: "Enamorus Incarnate Forme",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/905.png",
  },
  {
    id: 10249,
    name: "enamorus-therian",
    display_name: "Enamorus Therian Forme",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10249.png",
  },
  {
    id: 906,
    name: "sprigatito",
    display_name: "Sprigatito",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/906.png",
  },
  {
    id: 907,
    name: "floragato",
    display_name: "Floragato",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/907.png",
  },
  {
    id: 908,
    name: "meowscarada",
    display_name: "Meowscarada",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/908.png",
  },
  {
    id: 909,
    name: "fuecoco",
    display_name: "Fuecoco",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/909.png",
  },
  {
    id: 910,
    name: "crocalor",
    display_name: "Crocalor",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/910.png",
  },
  {
    id: 911,
    name: "skeledirge",
    display_name: "Skeledirge",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/911.png",
  },
  {
    id: 912,
    name: "quaxly",
    display_name: "Quaxly",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/912.png",
  },
  {
    id: 913,
    name: "quaxwell",
    display_name: "Quaxwell",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/913.png",
  },
  {
    id: 914,
    name: "quaquaval",
    display_name: "Quaquaval",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/914.png",
  },
  {
    id: 915,
    name: "lechonk",
    display_name: "Lechonk",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/915.png",
  },
  {
    id: 916,
    name: "oinkologne",
    display_name: "Oinkologne Male",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/916.png",
  },
  {
    id: 10254,
    name: "oinkologne-female",
    display_name: "Oinkologne Female",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10254.png",
  },
  {
    id: 917,
    name: "tarountula",
    display_name: "Tarountula",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/917.png",
  },
  {
    id: 918,
    name: "spidops",
    display_name: "Spidops",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/918.png",
  },
  {
    id: 919,
    name: "nymble",
    display_name: "Nymble",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/919.png",
  },
  {
    id: 920,
    name: "lokix",
    display_name: "Lokix",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/920.png",
  },
  {
    id: 921,
    name: "pawmi",
    display_name: "Pawmi",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/921.png",
  },
  {
    id: 922,
    name: "pawmo",
    display_name: "Pawmo",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/922.png",
  },
  {
    id: 923,
    name: "pawmot",
    display_name: "Pawmot",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/923.png",
  },
  {
    id: 924,
    name: "tandemaus",
    display_name: "Tandemaus",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/924.png",
  },
  {
    id: 925,
    name: "maushold",
    display_name: "Maushold Family of Four",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/925.png",
  },
  {
    id: 10257,
    name: "maushold-family-of-three",
    display_name: "Maushold Family of Three",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10257.png",
  },
  {
    id: 926,
    name: "fidough",
    display_name: "Fidough",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/926.png",
  },
  {
    id: 927,
    name: "dachsbun",
    display_name: "Dachsbun",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/927.png",
  },
  {
    id: 928,
    name: "smoliv",
    display_name: "Smoliv",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/928.png",
  },
  {
    id: 929,
    name: "dolliv",
    display_name: "Dolliv",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/929.png",
  },
  {
    id: 930,
    name: "arboliva",
    display_name: "Arboliva",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/930.png",
  },
  {
    id: 931,
    name: "squawkabilly",
    display_name: "Squawkabilly Green Plumage",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/931.png",
  },
  {
    id: 10260,
    name: "squawkabilly-blue-plumage",
    display_name: "Squawkabilly Blue Plumage",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10260.png",
  },
  {
    id: 10261,
    name: "squawkabilly-yellow-plumage",
    display_name: "Squawkabilly Yellow Plumage",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10261.png",
  },
  {
    id: 10262,
    name: "squawkabilly-white-plumage",
    display_name: "Squawkabilly White Plumage",
    hidden: true,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10262.png",
  },
  {
    id: 932,
    name: "nacli",
    display_name: "Nacli",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/932.png",
  },
  {
    id: 933,
    name: "naclstack",
    display_name: "Naclstack",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/933.png",
  },
  {
    id: 934,
    name: "garganacl",
    display_name: "Garganacl",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/934.png",
  },
  {
    id: 935,
    name: "charcadet",
    display_name: "Charcadet",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/935.png",
  },
  {
    id: 936,
    name: "armarouge",
    display_name: "Armarouge",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/936.png",
  },
  {
    id: 937,
    name: "ceruledge",
    display_name: "Ceruledge",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/937.png",
  },
  {
    id: 938,
    name: "tadbulb",
    display_name: "Tadbulb",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/938.png",
  },
  {
    id: 939,
    name: "bellibolt",
    display_name: "Bellibolt",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/939.png",
  },
  {
    id: 940,
    name: "wattrel",
    display_name: "Wattrel",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/940.png",
  },
  {
    id: 941,
    name: "kilowattrel",
    display_name: "Kilowattrel",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/941.png",
  },
  {
    id: 942,
    name: "maschiff",
    display_name: "Maschiff",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/942.png",
  },
  {
    id: 943,
    name: "mabosstiff",
    display_name: "Mabosstiff",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/943.png",
  },
  {
    id: 944,
    name: "shroodle",
    display_name: "Shroodle",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/944.png",
  },
  {
    id: 945,
    name: "grafaiai",
    display_name: "Grafaiai",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/945.png",
  },
  {
    id: 946,
    name: "bramblin",
    display_name: "Bramblin",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/946.png",
  },
  {
    id: 947,
    name: "brambleghast",
    display_name: "Brambleghast",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/947.png",
  },
  {
    id: 948,
    name: "toedscool",
    display_name: "Toedscool",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/948.png",
  },
  {
    id: 949,
    name: "toedscruel",
    display_name: "Toedscruel",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/949.png",
  },
  {
    id: 950,
    name: "klawf",
    display_name: "Klawf",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/950.png",
  },
  {
    id: 951,
    name: "capsakid",
    display_name: "Capsakid",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/951.png",
  },
  {
    id: 952,
    name: "scovillain",
    display_name: "Scovillain",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/952.png",
  },
  {
    id: 953,
    name: "rellor",
    display_name: "Rellor",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/953.png",
  },
  {
    id: 954,
    name: "rabsca",
    display_name: "Rabsca",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/954.png",
  },
  {
    id: 955,
    name: "flittle",
    display_name: "Flittle",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/955.png",
  },
  {
    id: 956,
    name: "espathra",
    display_name: "Espathra",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/956.png",
  },
  {
    id: 957,
    name: "tinkatink",
    display_name: "Tinkatink",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/957.png",
  },
  {
    id: 958,
    name: "tinkatuff",
    display_name: "Tinkatuff",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/958.png",
  },
  {
    id: 959,
    name: "tinkaton",
    display_name: "Tinkaton",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/959.png",
  },
  {
    id: 960,
    name: "wiglett",
    display_name: "Wiglett",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/960.png",
  },
  {
    id: 961,
    name: "wugtrio",
    display_name: "Wugtrio",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/961.png",
  },
  {
    id: 962,
    name: "bombirdier",
    display_name: "Bombirdier",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/962.png",
  },
  {
    id: 963,
    name: "finizen",
    display_name: "Finizen",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/963.png",
  },
  {
    id: 964,
    name: "palafin",
    display_name: "Palafin Zero Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/964.png",
  },
  {
    id: 10256,
    name: "palafin-hero",
    display_name: "Palafin Hero Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10256.png",
  },
  {
    id: 965,
    name: "varoom",
    display_name: "Varoom",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/965.png",
  },
  {
    id: 966,
    name: "revavroom",
    display_name: "Revavroom",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/966.png",
  },
  {
    id: 967,
    name: "cyclizar",
    display_name: "Cyclizar",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/967.png",
  },
  {
    id: 968,
    name: "orthworm",
    display_name: "Orthworm",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/968.png",
  },
  {
    id: 969,
    name: "glimmet",
    display_name: "Glimmet",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/969.png",
  },
  {
    id: 970,
    name: "glimmora",
    display_name: "Glimmora",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/970.png",
  },
  {
    id: 971,
    name: "greavard",
    display_name: "Greavard",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/971.png",
  },
  {
    id: 972,
    name: "houndstone",
    display_name: "Houndstone",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/972.png",
  },
  {
    id: 973,
    name: "flamigo",
    display_name: "Flamigo",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/973.png",
  },
  {
    id: 974,
    name: "cetoddle",
    display_name: "Cetoddle",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/974.png",
  },
  {
    id: 975,
    name: "cetitan",
    display_name: "Cetitan",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/975.png",
  },
  {
    id: 976,
    name: "veluza",
    display_name: "Veluza",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/976.png",
  },
  {
    id: 977,
    name: "dondozo",
    display_name: "Dondozo",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/977.png",
  },
  {
    id: 978,
    name: "tatsugiri",
    display_name: "Tatsugiri Curly Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/978.png",
  },
  {
    id: 10258,
    name: "tatsugiri-droopy",
    display_name: "Tatsugiri Droopy Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10258.png",
  },
  {
    id: 10259,
    name: "tatsugiri-stretchy",
    display_name: "Tatsugiri Stretchy Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10259.png",
  },
  {
    id: 979,
    name: "annihilape",
    display_name: "Annihilape",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/979.png",
  },
  {
    id: 980,
    name: "clodsire",
    display_name: "Clodsire",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/980.png",
  },
  {
    id: 981,
    name: "farigiraf",
    display_name: "Farigiraf",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/981.png",
  },
  {
    id: 982,
    name: "dudunsparce",
    display_name: "Dudunsparce Two-Segment Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/982.png",
  },
  {
    id: 10255,
    name: "dudunsparce-three-segment",
    display_name: "Dudunsparce Three-Segment Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10255.png",
  },
  {
    id: 983,
    name: "kingambit",
    display_name: "Kingambit",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/983.png",
  },
  {
    id: 984,
    name: "great-tusk",
    display_name: "Great Tusk",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/984.png",
  },
  {
    id: 985,
    name: "scream-tail",
    display_name: "Scream Tail",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/985.png",
  },
  {
    id: 986,
    name: "brute-bonnet",
    display_name: "Brute Bonnet",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/986.png",
  },
  {
    id: 987,
    name: "flutter-mane",
    display_name: "Flutter Mane",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/987.png",
  },
  {
    id: 988,
    name: "slither-wing",
    display_name: "Slither Wing",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/988.png",
  },
  {
    id: 989,
    name: "sandy-shocks",
    display_name: "Sandy Shocks",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/989.png",
  },
  {
    id: 990,
    name: "iron-treads",
    display_name: "Iron Treads",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/990.png",
  },
  {
    id: 991,
    name: "iron-bundle",
    display_name: "Iron Bundle",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/991.png",
  },
  {
    id: 992,
    name: "iron-hands",
    display_name: "Iron Hands",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/992.png",
  },
  {
    id: 993,
    name: "iron-jugulis",
    display_name: "Iron Jugulis",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/993.png",
  },
  {
    id: 994,
    name: "iron-moth",
    display_name: "Iron Moth",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/994.png",
  },
  {
    id: 995,
    name: "iron-thorns",
    display_name: "Iron Thorns",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/995.png",
  },
  {
    id: 996,
    name: "frigibax",
    display_name: "Frigibax",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/996.png",
  },
  {
    id: 997,
    name: "arctibax",
    display_name: "Arctibax",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/997.png",
  },
  {
    id: 998,
    name: "baxcalibur",
    display_name: "Baxcalibur",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/998.png",
  },
  {
    id: 999,
    name: "gimmighoul",
    display_name: "Gimmighoul Chest Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/999.png",
  },
  {
    id: 10263,
    name: "gimmighoul-roaming",
    display_name: "Gimmighoul Roaming Form",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/10263.png",
  },
  {
    id: 1000,
    name: "gholdengo",
    display_name: "Gholdengo",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/1000.png",
  },
  {
    id: 1001,
    name: "wo-chien",
    display_name: "Wo-Chien",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/1001.png",
  },
  {
    id: 1002,
    name: "chien-pao",
    display_name: "Chien-Pao",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/1002.png",
  },
  {
    id: 1003,
    name: "ting-lu",
    display_name: "Ting-Lu",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/1003.png",
  },
  {
    id: 1004,
    name: "chi-yu",
    display_name: "Chi-Yu",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/1004.png",
  },
  {
    id: 1005,
    name: "roaring-moon",
    display_name: "Roaring Moon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/1005.png",
  },
  {
    id: 1006,
    name: "iron-valiant",
    display_name: "Iron Valiant",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/1006.png",
  },
  {
    id: 1007,
    name: "koraidon",
    display_name: "Koraidon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/1007.png",
  },
  {
    id: 10264,
    name: "koraidon-limited-build",
    display_name: "Koraidon Limited Build",
    hidden: true,
    img_url: null,
  },
  {
    id: 10265,
    name: "koraidon-sprinting-build",
    display_name: "Koraidon Sprinting Build",
    hidden: true,
    img_url: null,
  },
  {
    id: 10266,
    name: "koraidon-swimming-build",
    display_name: "Koraidon Swimming Build",
    hidden: true,
    img_url: null,
  },
  {
    id: 10267,
    name: "koraidon-gliding-build",
    display_name: "Koraidon Gliding Build",
    hidden: true,
    img_url: null,
  },
  {
    id: 1008,
    name: "miraidon",
    display_name: "Miraidon",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/1008.png",
  },
  {
    id: 10268,
    name: "miraidon-low-power-mode",
    display_name: "Miraidon Low-Power Mode",
    hidden: true,
    img_url: null,
  },
  {
    id: 10269,
    name: "miraidon-drive-mode",
    display_name: "Miraidon Drive Mode",
    hidden: true,
    img_url: null,
  },
  {
    id: 10270,
    name: "miraidon-aquatic-mode",
    display_name: "Miraidon Aquatic Mode",
    hidden: true,
    img_url: null,
  },
  {
    id: 10271,
    name: "miraidon-glide-mode",
    display_name: "Miraidon Glide Mode",
    hidden: true,
    img_url: null,
  },
  {
    id: 1009,
    name: "walking-wake",
    display_name: "Walking Wake",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/1009.png",
  },
  {
    id: 1010,
    name: "iron-leaves",
    display_name: "Iron Leaves",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/1010.png",
  },
  {
    id: 1011,
    name: "dipplin",
    display_name: "Dipplin",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/1011.png",
  },
  {
    id: 1012,
    name: "poltchageist",
    display_name: "Poltchageist",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/1012.png",
  },
  {
    id: 1013,
    name: "sinistcha",
    display_name: "Sinistcha",
    hidden: false,
    img_url: "https://archives.bulbagarden.net/media/upload/9/98/HOME1013.png",
  },
  {
    id: 1014,
    name: "okidogi",
    display_name: "Okidogi",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/1014.png",
  },
  {
    id: 1015,
    name: "munkidori",
    display_name: "Munkidori",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/1015.png",
  },
  {
    id: 1016,
    name: "fezandipiti",
    display_name: "Fezandipiti",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/1016.png",
  },
  {
    id: 1017,
    name: "ogerpon",
    display_name: "Ogerpon Teal Mask",
    hidden: false,
    img_url:
      "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/1017.png",
  },
  {
    id: 10273,
    name: "ogerpon-wellspring-mask",
    display_name: "Ogerpon Wellspring Mask",
    hidden: false,
    img_url: "/img/pokemon-sprites/10273.png",
  },
  {
    id: 10274,
    name: "ogerpon-hearthflame-mask",
    display_name: "Ogerpon Hearthflame Mask",
    hidden: false,
    img_url: "/img/pokemon-sprites/10274.png",
  },
  {
    id: 10275,
    name: "ogerpon-cornerstone-mask",
    display_name: "Ogerpon Cornerstone Mask",
    hidden: false,
    img_url: "/img/pokemon-sprites/10275.png",
  },
];
