export const PRECISION = 3;
export const MAX_GUESSES = 9;

export const scoreWords = {
  0: "Bad Luck",
  1: "Room to improve",
  2: "Respectable",
  3: "Decent",
  4: "Nice Work",
  5: "Awesome",
  6: "Impressive",
  7: "Outstanding",
  8: "Magnificent",
  9: "PERFECT",
};
