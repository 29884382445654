import { handleTweet } from "../../lib/utils/share";
import { trackShare } from "../../lib/utils/analytics";
import { gridLevelNumber } from "../../lib/utils/gridLevels";

function TweetButton() {
  const tweetCopy = handleTweet();
  const href =
    "https://twitter.com/intent/tweet?text=" + encodeURIComponent(tweetCopy);

  function trackClick() {
    trackShare({ method: "Twitter", itemId: gridLevelNumber });
  }

  return (
    <a
      href={href}
      target="_blank"
      onClick={trackClick}
      className="mb-2 mr-2 inline-flex items-center rounded-lg  bg-[#1d9bf0] px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-[#1a78b6] focus:outline-none focus:ring-4 focus:ring-blue-200 dark:border-gray-700 dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-600"
    >
      <svg
        aria-hidden="true"
        className="-ml-1 mr-2 h-5 w-6"
        viewBox="0 0 16 16"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0016 3.542a6.658 6.658 0 01-1.889.518 3.301 3.301 0 001.447-1.817 6.533 6.533 0 01-2.087.793A3.286 3.286 0 007.875 6.03a9.325 9.325 0 01-6.767-3.429 3.289 3.289 0 001.018 4.382A3.323 3.323 0 01.64 6.575v.045a3.288 3.288 0 002.632 3.218 3.203 3.203 0 01-.865.115 3.23 3.23 0 01-.614-.057 3.283 3.283 0 003.067 2.277A6.588 6.588 0 01.78 13.58a6.32 6.32 0 01-.78-.045A9.344 9.344 0 005.026 15z" />
      </svg>
      <span className="hidden md:inline-block">Tweet Score</span>
      <span className="md:hidden">Tweet</span>
    </a>
  );
}

export default TweetButton;
