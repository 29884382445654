import { format } from "date-fns";
import { en, de, ko, fr, es, it, ja, zhCN } from "date-fns/locale";

const locales = {
  en: en,
  de: de,
  ko: ko,
  fr: fr,
  es: es,
  it: it,
  ja: ja,
  zh: zhCN,
};

function setLocale(params) {
  const userLocale = navigator.language || "en-US";
  const locale = userLocale.split("-")[0];
  window.__localeId__ = locale;
}

export function formatDate(date, formatStr = "PP") {
  if (!window.__localeId__) {
    setLocale();
  }

  return format(date, formatStr, {
    locale: locales[window.__localeId__ || en],
  });
}
