import { addDays } from "./dateFunctions";
import { getMidnight, getDaysBetweenDates, dateToKey } from "./dateFunctions";

const startDate = new Date("Dec 4 2023");
const startDateMidnight = getMidnight(startDate);
const todayMidnight = getMidnight(new Date());

export const gridLevelNumber =
  getDaysBetweenDates(todayMidnight, startDateMidnight) + 1;

export const gridLevelDateKey =
  extractDateFromURLPath() ?? getURLParameter("preview") ?? dateToKey();

export function getDateKeyForLevel(level) {
  const levelDate = addDays(startDateMidnight, level - 1);

  return dateToKey(levelDate);
}

function getURLParameter(name) {
  if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
  } else {
    return null;
  }
}

export function extractDateFromURLPath() {
  if (typeof window !== "undefined") {
    const currentPath = window.location.pathname;
    const regex = /\/(\d{4}-\d{2}-\d{2})/;
    const match = currentPath.match(regex);
    if (match && match[1]) {
      return match[1];
    } else {
      return null;
    }
  } else {
    return null;
  }
}
