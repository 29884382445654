import { createWithEqualityFn } from "zustand/traditional";
import { persist } from "zustand/middleware";
import {
  getDateKeyForLevel,
  gridLevelDateKey,
  gridLevelNumber,
} from "../utils/gridLevels";
import { MAX_GUESSES } from "../utils/constants";
import { generateId } from "../utils/generateId";
import useGameStore from "./GameStore";
import { postSubmission } from "../utils/submit";
import { trackGiveUp, trackLevelEnd, trackUndo } from "../utils/analytics";
import { dateKeyToDate } from "../utils/dateFunctions";

const usePersistentStore = createWithEqualityFn(
  persist(
    (set, get) => ({
      browserId: generateId(),
      lastPlayed: null,
      saveLastPlayed: (number) =>
        set(() => ({
          lastPlayed: number,
        })),

      history: {},
      userHistory: {},

      undoUsed: false,
      setUndoUsed: (isUndoUsed) => set((state) => ({ undoUsed: isUndoUsed })),
      submitted: false,
      setSubmitted: (isSubmitted) =>
        set((state) => ({ submitted: isSubmitted })),

      cardMode: true,
      toggleCardMode: () =>
        set((prevState) => ({ cardMode: !prevState.cardMode })),

      darkMode: false,
      toggleDarkMode: () =>
        set((prevState) => ({ darkMode: !prevState.darkMode })),

      seenRarity: 0,
      setSeenRarity: (level) => set((state) => ({ seenRarity: level })),

      score: 0,
      incorrectGuesses: [
        [[], [], []],
        [[], [], []],
        [[], [], []],
      ],
      correctGuesses: [
        [null, null, null],
        [null, null, null],
        [null, null, null],
      ],
      setCorrectGuesses: (array) => set((state) => ({ correctGuesses: array })),

      guessesLeft: MAX_GUESSES,
      setGuessesLeft: (remaining) =>
        set((state) => ({ guessesLeft: remaining })),

      mergeRemoteAttempt: async ({ user, attempt }) => {
        console.log({ attempt });

        const attemptData = {
          level: attempt.gridId,
          guessesLeft: 0,
          correctGuesses: attempt.answers,
          incorrectGuesses: attempt.incorrectGuesses || [
            [[], [], []],
            [[], [], []],
            [[], [], []],
          ],
          undoUsed: attempt.undoUsed,
          gaveUp: attempt.gaveUp,
          score: attempt.answers.flat().filter((e) => e !== null).length,
        };

        set((prevState) => {
          // Take a copy of history
          const updatedUserHistory = Object.assign({}, prevState.userHistory);

          // Add record for user id
          updatedUserHistory[user.id] = Object.assign(
            {},
            prevState.userHistory[user.id],
            { [gridLevelDateKey]: attemptData }
          );

          return {
            userHistory: updatedUserHistory,
          };
        });
      },

      setBoard: (user) => {
        console.log("Setting board...");
        // Try to set up game state in this order
        // 1. userHistory[user][datekey]
        // 2. userHistory[lastUser][datekey]
        // 3. history[datekey]
        const userHistory = get().userHistory;
        const history = get().history;

        if (user) {
          console.log("Set board using userHistory");
          const userAttempts = userHistory[user.id];
          const attempt = userAttempts ? userAttempts[gridLevelDateKey] : null;

          if (attempt) {
            console.log("Found user history for " + gridLevelDateKey);
            // User logged in after previously playing, clear current grid with
            const currentDayState = {
              lastPlayed: gridLevelDateKey,
              guessesLeft: attempt.guessesLeft,
              correctGuesses: attempt.correctGuesses,
              incorrectGuesses: attempt.incorrectGuesses || [
                [[], [], []],
                [[], [], []],
                [[], [], []],
              ],
              undoUsed: attempt.undoUsed,
              gaveUp: attempt.gaveUp,
              submitted: attempt.guessesLeft == 0,
              score: attempt.score,
            };

            set((prevState) => ({
              ...currentDayState,
            }));
          } else {
            console.log("No stored user attempt for " + gridLevelDateKey);
            const localAttempt = history[gridLevelDateKey];
            if (localAttempt) {
              console.log("Found a local attempt, claiming as own");

              get().updateUserHistory(user, {
                [gridLevelDateKey]: localAttempt,
              });
              get().deleteTodayFromLocalHistory();

              const currentDayState = {
                lastPlayed: gridLevelDateKey,
                guessesLeft: localAttempt.guessesLeft,
                correctGuesses: localAttempt.correctGuesses,
                incorrectGuesses: localAttempt.incorrectGuesses || [
                  [[], [], []],
                  [[], [], []],
                  [[], [], []],
                ],
                undoUsed: localAttempt.undoUsed,
                gaveUp: localAttempt.gaveUp,
                submitted: localAttempt.guessesLeft == 0,
                score: localAttempt.score,
              };

              set((prevState) => ({
                ...currentDayState,
              }));

              if (localAttempt.guessesLeft == 0) {
                console.log("Local attempt is complete -> Sync");
                postSubmission({
                  gridKey: gridLevelDateKey,
                  browserId: get().browserId,
                  correctGuesses: localAttempt.correctGuesses,
                  incorrectGuesses: localAttempt.incorrectGuesses || [
                    [[], [], []],
                    [[], [], []],
                    [[], [], []],
                  ],
                  gaveUp: localAttempt.gaveUp,
                  undoUsed: localAttempt.undoUsed,
                });
              } else {
                console.log("Local was not complete -> don't sync");
              }
            }
          }
        } else {
          console.log("No user, set board using anonymous history");
          const attempt = history[gridLevelDateKey];

          if (attempt) {
            console.log("Found anon history");
            // User logged in after previously playing, clear current grid with
            const currentDayState = {
              lastPlayed: gridLevelDateKey,
              guessesLeft: attempt.guessesLeft,
              correctGuesses: attempt.correctGuesses,
              incorrectGuesses: attempt.incorrectGuesses || [
                [[], [], []],
                [[], [], []],
                [[], [], []],
              ],
              undoUsed: attempt.undoUsed,
              gaveUp: attempt.gaveUp,
              submitted: attempt.guessesLeft == 0,
              score: attempt.score,
            };
            set((prevState) => ({
              ...currentDayState,
            }));
          } else {
            console.log("No anon history found");

            const newState = {
              correctGuesses: [
                [null, null, null],
                [null, null, null],
                [null, null, null],
              ],
              incorrectGuesses: [
                [[], [], []],
                [[], [], []],
                [[], [], []],
              ],
              guessesLeft: MAX_GUESSES,
              score: 0,
              undoUsed: false,
              gaveUp: false,
              submitted: false,
            };
            set((prevState) => ({
              ...newState,
            }));
          }
        }
      },

      loadSubmission: (submission) => {
        // PLAN TO DELETE
        // const guessesLeft = get().guessesLeft;
        // if (submission == null) {
        //   if (guessesLeft == 0) {
        //     console.log("resetting game for new user who logged in");
        //     const newState = {
        //       correctGuesses: [
        //         [null, null, null],
        //         [null, null, null],
        //         [null, null, null],
        //       ],
        //       incorrectGuesses: [
        //         [[], [], []],
        //         [[], [], []],
        //         [[], [], []],
        //       ],
        //       guessesLeft: MAX_GUESSES,
        //       score: 0,
        //       undoUsed: false,
        //       gaveUp: false,
        //       submitted: false,
        //     };
        //     set((prevState) => ({
        //       ...newState,
        //     }));
        //   } else {
        //     console.log("keeping incomplete game active");
        //   }
        // } else {
        //   console.log("restoring saved game");
        //   const newState = {
        //     lastPlayed: gridLevelDateKey,
        //     guessesLeft: 0,
        //     correctGuesses: submission.answers,
        //     incorrectGuesses: [
        //       [[], [], []],
        //       [[], [], []],
        //       [[], [], []],
        //     ],
        //     undoUsed: submission.undoUsed,
        //     gaveUp: submission.gaveUp,
        //     submitted: true,
        //     score: submission.answers.flat().filter((e) => e !== null).length,
        //   };
        //   set((prevState) => ({
        //     ...newState,
        //   }));
        // }
      },

      updateUserHistory: (user, todaysGuess) => {
        set((prevState) => {
          // Take a copy of entire user history
          const updatedUserHistory = Object.assign({}, prevState.userHistory);

          // Update record for current user
          updatedUserHistory[user.id] = Object.assign(
            {}, // Start with an empty object
            prevState.userHistory[user.id], // Add anything that was already in userHistory
            todaysGuess // Finally add latest info on today's guess
          );

          return {
            userHistory: updatedUserHistory,
          };
        });
      },

      getLocalGridHistory: () => {
        const user = useGameStore.getState().user;
        const userHistory = get().userHistory;
        const localHistory = get().history;

        const allGrids = [];
        for (
          let level = gridLevelNumber;
          level >= gridLevelNumber - 6;
          level--
        ) {
          const dateKey = getDateKeyForLevel(level);
          const date = dateKeyToDate(dateKey);
          const localAttempt = localHistory[dateKey];
          const userAttempt = userHistory?.[user?.id]?.[dateKey] ?? null;

          const attempt = userAttempt || localAttempt || null;

          allGrids.push({
            level: level,
            dateKey: dateKey,
            date: date,
            attempt,
          });
        }
        return allGrids;
      },

      updateLocalHistory: (todaysGuess) => {
        set((prevState) => ({
          history: Object.assign(prevState.history, todaysGuess),
        }));
      },

      deleteTodayFromLocalHistory: () => {
        set((prevState) => {
          // Take a copy of entire user history
          const updatedHistory = Object.assign({}, prevState.history);
          delete updatedHistory[gridLevelDateKey];

          return {
            history: updatedHistory,
          };
        });
      },

      undoGuess: (pokemon, row, col) => {
        const correctGuesses = get().correctGuesses;
        const incorrectGuesses = get().incorrectGuesses;
        const grid = useGameStore.getState().grid;

        // Reset correct answer to null
        if (correctGuesses[row - 1][col - 1] == pokemon.id) {
          correctGuesses[row - 1][col - 1] = null;
        }

        // Remove from array of wrong guesses
        incorrectGuesses[row - 1][col - 1] = incorrectGuesses[row - 1][
          col - 1
        ].filter((pokemonId) => pokemonId !== pokemon.id);

        trackUndo({ pokemon, row, col, levelNumber: grid.levelNumber });

        const todaysGuess = {
          [gridLevelDateKey]: {
            level: grid.levelNumber,
            score: correctGuesses.flat().filter((e) => e !== null).length,
            incorrectGuesses: incorrectGuesses,
            correctGuesses: correctGuesses,
            guessesLeft: get().guessesLeft + 1,
            undoUsed: true,
            gaveUp: get().gaveUp,
          },
        };

        set((prevState) => ({
          guessesLeft: prevState.guessesLeft + 1,
          correctGuesses: correctGuesses,
          incorrectGuesses: incorrectGuesses,
          undoUsed: true,
          gaveUp: prevState.gaveUp,
          submitted: prevState.submitted,
          score: correctGuesses.flat().filter((e) => e !== null).length,
        }));

        // If user, update userHistory
        const user = useGameStore.getState().user;
        if (user) {
          get().updateUserHistory(user, todaysGuess);
        } else {
          get().updateLocalHistory(todaysGuess);
        }
      },

      persistGuess: (pokemon, row, col) => {
        const correctGuesses = get().correctGuesses;
        const incorrectGuesses = get().incorrectGuesses;
        const grid = useGameStore.getState().grid;

        const correctAnswers = Object.keys(
          grid.correctAnswersPlayerMap[row - 1][col - 1]
        ).map((pokemonId) => parseInt(pokemonId));

        if (correctAnswers.includes(pokemon.id)) {
          correctGuesses[row - 1][col - 1] = pokemon.id;
        } else {
          incorrectGuesses[row - 1][col - 1].push(pokemon.id);
        }

        const gridKey = gridLevelDateKey;
        const browserId = get().browserId;
        const guessesLeft = get().guessesLeft - 1;
        const alreadySubmitted = get().submitted;

        if (guessesLeft <= 0) {
          if (!alreadySubmitted) {
            trackLevelEnd({
              gameName: useGameStore.getState().analyticsGameName,
              levelNumber: grid.levelNumber,
            });
          }

          postSubmission({
            gridKey,
            browserId,
            correctGuesses,
            incorrectGuesses,
            gaveUp: get().gaveUp,
            undoUsed: get().undoUsed,
          });
        }

        const todaysGuess = {
          [gridLevelDateKey]: {
            level: grid.levelNumber,
            score: correctGuesses.flat().filter((e) => e !== null).length,
            incorrectGuesses: incorrectGuesses,
            correctGuesses: correctGuesses,
            guessesLeft: guessesLeft,
            undoUsed: get().undoUsed,
            gaveUp: get().gaveUp,
          },
        };
        set((prevState) => ({
          lastPlayed: gridLevelDateKey,
          guessesLeft: guessesLeft,
          correctGuesses: correctGuesses,
          incorrectGuesses: incorrectGuesses,
          gaveUp: prevState.gaveUp,
          submitted: prevState.submitted,
          score: correctGuesses.flat().filter((e) => e !== null).length,
        }));

        // If user, update userHistory
        const user = useGameStore.getState().user;
        if (user) {
          get().updateUserHistory(user, todaysGuess);
        } else {
          get().updateLocalHistory(todaysGuess);
        }
      },

      gaveUp: false,
      setGaveUp: (isGaveUp) => set((state) => ({ gaveUp: isGaveUp })),
      giveUp: () => {
        const correctGuesses = get().correctGuesses;
        const incorrectGuesses = get().incorrectGuesses;
        const grid = useGameStore.getState().grid;
        const gridKey = gridLevelDateKey;
        const browserId = get().browserId;
        const guessesLeft = get().guessesLeft;
        const alreadySubmitted = get().submitted;

        // Only submit if user made at least 3 guesses
        if (guessesLeft <= 6) {
          if (!alreadySubmitted) {
            trackLevelEnd({
              gameName: useGameStore.getState().analyticsGameName,
              levelNumber: grid.levelNumber,
            });
            trackGiveUp({
              gameName: useGameStore.getState().analyticsGameName,
              levelNumber: grid.levelNumber,
            });
          }

          postSubmission({
            gridKey,
            browserId,
            correctGuesses,
            incorrectGuesses,
            gaveUp: true,
            undoUsed: get().undoUsed,
          });
        }

        const todaysGuess = {
          [gridLevelDateKey]: {
            level: grid.levelNumber,
            score: correctGuesses.flat().filter((e) => e !== null).length,
            incorrectGuesses: incorrectGuesses,
            correctGuesses: correctGuesses,
            guessesLeft: 0,
            undoUsed: get().undoUsed,
            gaveUp: true,
          },
        };
        set((prevState) => ({
          lastPlayed: gridLevelDateKey,
          guessesLeft: 0,
          correctGuesses: correctGuesses,
          incorrectGuesses: incorrectGuesses,
          gaveUp: true,
          submitted: prevState.submitted,
          score: correctGuesses.flat().filter((e) => e !== null).length,
        }));

        // If user, update userHistory
        const user = useGameStore.getState().user;
        if (user) {
          get().updateUserHistory(user, todaysGuess);
        } else {
          get().updateLocalHistory(todaysGuess);
        }
      },

      isTodaysTipsOpen: gridLevelDateKey,
      setTodaysTipsOpen: () =>
        set((state) => ({ isTodaysTipsOpen: gridLevelDateKey })),

      instructionsDismissed: false,
      saveInstructionsDismissed: (isDismissed) =>
        set(() => ({
          instructionsDismissed: isDismissed,
        })),
    }),
    {
      name: "grid",

      merge: (persistedState, currentState) => {
        if (persistedState.lastPlayed !== gridLevelDateKey) {
          console.log(
            `Level change ${persistedState.lastPlayed} -> ${gridLevelDateKey}, clearing guesses`
          );
          persistedState.incorrectGuesses = [
            [[], [], []],
            [[], [], []],
            [[], [], []],
          ];
          persistedState.correctGuesses = [
            [null, null, null],
            [null, null, null],
            [null, null, null],
          ];
          persistedState.guessesLeft = MAX_GUESSES;
          persistedState.score = 0;
          persistedState.undoUsed = false;
          persistedState.gaveUp = false;
          persistedState.submitted = false;
        }

        return { ...currentState, ...persistedState };
      },
    }
  ),
  Object.is
);

export default usePersistentStore;
