import React, { useState } from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Tooltip from "@mui/material/Tooltip";
import { gridLevelDateKey } from "../lib/utils/gridLevels";
import usePersistentStore from "../lib/store/PersistentStore";
import { shallow } from "zustand/shallow";
import { PokemonTypeImage } from "./PokemonTypeImage";

export function CategoryHeaderTooltip({ item, pos, selectedTab }) {
  const [lastPlayed] = usePersistentStore(
    (state) => [state.lastPlayed],
    shallow
  );

  const [open, setOpen] = useState(
    selectedTab == "my" && item.showOnLaunch && lastPlayed != gridLevelDateKey
  );

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <div className="font-display flex h-12 items-center px-px text-center font-bold leading-tight text-gray-800 dark:text-white sm:h-20 sm:px-1 sm:text-xl md:h-24 md:text-2xl">
      {item.description ? (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
              sx: {
                zIndex: 5,
              },
            }}
            componentsProps={{
              tooltip: {
                sx: {
                  zIndex: 5,
                  fontSize: "0.875rem",
                  lineHeight: "1.3",
                  fontWeight: 400,
                  fontFamily: "Lato",
                  padding: "0.75rem 1rem",
                  textAlign: "left",
                  bgcolor: "rgba(0, 0, 0, 0.9)",
                  "& .MuiTooltip-arrow": {
                    color: "common.black",
                  },
                },
              },
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            placement={pos == "col" ? "bottom" : "bottom-start"}
            arrow
            title={item.description}
          >
            <button
              onClick={handleTooltipOpen}
              className="flex cursor-help flex-col items-center justify-center"
            >
              {item.isSubtitleFirst && (
                <div className="text-xxs font-semibold uppercase text-gray-500 dark:text-gray-400 sm:text-md">
                  {item.subtitle}
                </div>
              )}
              {item.type == "pokemon-type" ? (
                <>
                  <PokemonTypeImage
                    typeId={item.id}
                    className="h-12 w-12 rounded-full sm:mb-2 sm:h-14 sm:w-14 md:h-16 md:w-16"
                  />
                  <div className="hidden text-base uppercase leading-tight text-gray-600 sm:block md:text-lg">
                    {item.title}
                  </div>
                </>
              ) : item.imgUrl ? (
                <img
                  className="mb-1 mt-1 h-16 w-16 sm:h-24 sm:w-24 md:h-36 md:w-36"
                  src={item.imgUrl}
                  alt={item.title}
                ></img>
              ) : (
                <div className="leading-tight">{item.title}</div>
              )}
              {!item.isSubtitleFirst && (
                <div className="text-xxs font-semibold uppercase text-gray-500 dark:text-gray-400 sm:text-md">
                  {item.subtitle}
                </div>
              )}
            </button>
          </Tooltip>
        </ClickAwayListener>
      ) : (
        <div>
          {item.isSubtitleFirst && (
            <div className="text-xxs font-semibold uppercase text-gray-500 dark:text-gray-400 sm:text-md">
              {item.subtitle}
            </div>
          )}
          <div className="leading-tight">{item.title}</div>
          {!item.isSubtitleFirst && (
            <div className="text-xxs font-semibold uppercase text-gray-500 dark:text-gray-400 sm:text-md">
              {item.subtitle}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export function CategoryHeaderSmall({ item }) {
  return (
    <div className="font-display flex h-20 flex-col items-center justify-center text-center text-xs font-bold leading-tight text-gray-800 dark:text-white sm:h-28 sm:text-sm  ">
      {item.isSubtitleFirst && (
        <div className="text-xxs font-semibold uppercase text-gray-500 dark:text-gray-400 sm:text-xs">
          {item.subtitle}
        </div>
      )}
      {item.imgUrl ? (
        <img
          className="mb-1 mt-1 h-16 w-16 sm:h-24 sm:w-24"
          src={item.imgUrl}
          alt={item.title}
        ></img>
      ) : (
        <div className="leading-tight sm:text-lg">{item.title}</div>
      )}
      {!item.isSubtitleFirst && (
        <div className="text-xxs font-semibold uppercase text-gray-500 dark:text-gray-400 sm:text-xs">
          {item.subtitle}
        </div>
      )}
    </div>
  );
}
