import { trackEvent, trackSelect } from "../../lib/utils/analytics";
import useGameStore from "../../lib/store/GameStore";

export function HowToPlayLink() {
  const [setIsOpen] = useGameStore((state) => [state.setIsHelpOpen]);

  function openModal() {
    setIsOpen(true);
    trackEvent("how_to_play_button");
    trackSelect({
      contentType: "button",
      itemId: "how_to_play",
    });
  }

  return (
    <button onClick={openModal} className="hover:underline">
      How To Play
    </button>
  );
}
