export function rarityScheme(selectedPct) {
  const data = {
    unique: {
      id: "unique",
      level: 9,
      class: "unique dark",
      word: "Legendary",
      emoji: "🔮",
      toolTip: "Selected by less than 1% of PokeGrid players.",
      dark: true,
      emojiSquare: "🔮",
    },
    legendary: {
      id: "legendary",
      level: 7,
      class: "legendary",
      word: "Ultra Rare",
      emoji: "✨",
      toolTip: "Selected by between 1-5% of PokeGrid players.",
      dark: true,
      emojiSquare: "🟪",
    },
    epic: {
      id: "epic",
      level: 5,
      class: "epic",
      word: "Rare",
      emoji: "✨",
      toolTip: "Selected by between 5-10% of PokeGrid players.",
      dark: false,
      emojiSquare: "🟦",
    },
    rare: {
      id: "rare",
      level: 3,
      class: "rare",
      word: "Uncommon",
      emoji: "✨",
      toolTip: "Selected by between 10-20% of PokeGrid players.",
      dark: false,
      emojiSquare: "🟨",
    },
    common: {
      id: "common",
      level: 1,
      class: "common",
      word: "Common",
      emoji: null,
      toolTip: null,
      dark: false,
      emojiSquare: "🟩",
    },
  };

  if (selectedPct < 1) {
    return data["unique"];
  } else if (selectedPct <= 5) {
    return data["legendary"];
  } else if (selectedPct <= 10) {
    return data["epic"];
  } else if (selectedPct <= 20) {
    return data["rare"];
  }

  return data["common"];
}
