export function Divider() {
  return (
    <div
      className="mt-8 h-px"
      style={{
        backgroundImage:
          "linear-gradient(270deg,hsl(0deg 0% 30% / 0%) 0,hsl(0deg 0% 20% / 17%) 50%,hsla(0,0%,30%,0))",
      }}
    ></div>
  );
}
