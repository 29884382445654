import ShareButton from "../buttons/ShareButton";
import { scoreWords } from "../../lib/utils/constants";
import CountUp from "react-countup";
import { calcRarity } from "../../lib/utils/calcRarity";
import TweetButton from "../buttons/TweetButton";
import ToolTip from "./ToolTip";
import { BaseDialog } from "./BaseDialog";
import { DailySummary } from "./StatsDialog";
import { rarityScheme } from "../../lib/utils/rarityScheme";
import useGameStore from "../../lib/store/GameStore";
import usePersistentStore from "../../lib/store/PersistentStore";

function ResultDialog(props) {
  const [isOpen, setIsOpen, globalStats, levelNumber] = useGameStore(
    (state) => [
      state.isResultOpen,
      state.setIsResultOpen,
      state.globalStats,
      state.levelNumber,
    ]
  );
  const [score, correctGuesses] = usePersistentStore((state) => [
    state.score,
    state.correctGuesses,
  ]);

  const rarity = calcRarity(correctGuesses, globalStats);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <BaseDialog
      isOpen={isOpen}
      closeModal={closeModal}
      modalTitle={
        <div className="">
          <div className="text-center text-2xl font-semibold uppercase dark:text-white">
            Summary
          </div>
          <div className="text-center text-sm font-semibold uppercase text-gray-500 dark:text-gray-300">
            PokeGrid {levelNumber}
          </div>
        </div>
      }
      modalBody={
        <ModalBody score={score} rarity={rarity} footer={props.footer} />
      }
      size="lg"
    />
  );
}

function ModalBody({ score, rarity, footer }) {
  const scoreWord = scoreWords[score];

  return (
    <div className="-mb-4 mt-2 text-center">
      <div>
        <div className="flex justify-center">
          <ResultCell row={1} col={1} />
          <ResultCell row={1} col={2} />
          <ResultCell row={1} col={3} />
        </div>
        <div className="flex justify-center">
          <ResultCell row={2} col={1} />
          <ResultCell row={2} col={2} />
          <ResultCell row={2} col={3} />
        </div>
        <div className="flex justify-center">
          <ResultCell row={3} col={1} />
          <ResultCell row={3} col={2} />
          <ResultCell row={3} col={3} />
        </div>
      </div>
      <div className="mt-4">
        <div className="flex items-center justify-center text-center text-sm  sm:text-base">
          <span className="font-semibold uppercase text-gray-700 dark:text-gray-200 ">
            Rarity Score
          </span>
          <ToolTip
            title="Rarity score is calculated as the sum of the percentages for each correct cell, plus 100 for each empty cell. A lower score means your answers are more rare. This score will change throughout the day as more games are completed."
            iconClassName="ml-1 h-4 w-4"
          />
        </div>
        <div className="text-center text-5xl font-semibold leading-tight tracking-tight">
          <CountUp
            decimals={rarity < 100 ? 1 : 0}
            end={rarity}
            useEasing={false}
            duration={0.4}
          />
        </div>
      </div>
      <div className="my-2 mt-2 border-solid border-slate-200 px-2.5">
        <h2 className="mb-5 text-2xl font-medium uppercase leading-tight md:text-3xl">
          {scoreWord}!
        </h2>
        <div className="mb-8 mt-8 flex items-center justify-center">
          <TweetButton />
          <ShareButton />
        </div>
      </div>
      {/* <div className="-mx-6 mt-6 bg-yellow-100 p-2 text-center">
        <p className="font-bold ">Want more daily games?</p>
        <p>
          Try today's{" "}
          <a
            href="https://playworpel.com/?utm_source=gridley&utm_medium=affiliate&utm_campaign=results"
            target="_blank"
            className="font-bold text-blue-600  hover:text-blue-400"
          >
            Worpel
          </a>{" "}
          and{" "}
          <a
            href="https://playworpel.com/legends?utm_source=gridley&utm_medium=affiliate&utm_campaign=results"
            target="_blank"
            className="font-bold text-blue-600  hover:text-blue-400"
          >
            Worpel Legends
          </a>
          !
        </p>
      </div> */}
      {/* <DailySummary /> */}
    </div>
  );
}

function ResultCell({ row, col }) {
  const [correctGuesses, cardMode] = usePersistentStore((state) => [
    state.correctGuesses,
    state.cardMode,
  ]);
  const [globalStats] = useGameStore((state) => [state.globalStats]);

  const correctAnswersPlayerMap =
    globalStats?.correctAnswersPlayerMap[row - 1][col - 1];
  const correctPokemonId = correctGuesses[row - 1][col - 1];
  const pct = correctAnswersPlayerMap
    ? (correctAnswersPlayerMap[correctPokemonId] / globalStats?.completed) * 100
    : 0.1;

  const scheme = rarityScheme(pct);
  const bg = cardMode ? scheme.id + "-flat" : "bg-[#59d185]";

  return (
    <div
      className={`bg-x-center m-1 h-12 w-12 rounded-lg md:h-16 md:w-16 ${
        correctPokemonId ? bg : "bg-gray-100 dark:bg-gray-700"
      } `}
    ></div>
  );
}

export default ResultDialog;
