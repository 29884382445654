export function trackShare({ method, itemId }) {
  if (typeof window !== "undefined") {
    window.gtag("event", "share", {
      method: method,
      content_type: "text",
      item_id: itemId,
    });
  }
}

export function trackFriendCodeShare({ method }) {
  if (typeof window !== "undefined") {
    window.gtag("event", "share", {
      method: method,
      content_type: "text",
      item_id: "code",
    });
  }
}

export function trackSelect({ contentType, itemId }) {
  if (typeof window !== "undefined") {
    window.gtag("event", "select_content", {
      content_type: contentType,
      item_id: itemId,
    });
  }
}

export function trackEvent(name, params = {}) {
  if (typeof window !== "undefined") {
    window.gtag("event", name, params);
  }
}

export function trackUndo({ pokemon, row, col, levelNumber }) {
  if (typeof window !== "undefined") {
    window.gtag("event", "undo_guess", {
      cell: `${row},${col}`,
    });
  }
}

export function trackLevelStart({ gameName, levelNumber }) {
  if (typeof window !== "undefined") {
    window.gtag("event", "level_start", {
      level_name: `${gameName} ${levelNumber}`,
    });

    // Google ads conversion tracking
    gtag_report_conversion();
  }
}

export function trackLevelEnd({ gameName, levelNumber }) {
  if (typeof window !== "undefined") {
    window.gtag("event", "level_end", {
      level_name: `${gameName} ${levelNumber}`,
    });
  }
}

export function trackGiveUp({ gameName, levelNumber }) {
  if (typeof window !== "undefined") {
    window.gtag("event", "give_up", {
      level_name: `${gameName} ${levelNumber}`,
    });
  }
}

function gtag_report_conversion(url) {
  var callback = function () {
    if (typeof url != "undefined") {
      window.location = url;
    }
  };
  window.gtag("event", "conversion", {
    // send_to: "AW-1039453308/HimRCNGussIYEPyY0-8D",
    send_to: "G-L6S6177KVW/HimRCNGussIYEPyY0-8D",
    event_callback: callback,
  });
  return false;
}
