import useGameStore from "../store/GameStore";
import usePersistentStore from "../store/PersistentStore";
import { mutate } from "swr";
import { gridLevelDateKey } from "./gridLevels";

export function postSubmission({
  gridKey,
  browserId,
  correctGuesses,
  incorrectGuesses,
  undoUsed = false,
  gaveUp = false,
}) {
  const url = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/submit`;
  const data = {
    gridKey: gridKey,
    browserId: browserId,
    correctAnswers: correctGuesses,
    incorrectGuesses: incorrectGuesses,
    undoUsed: undoUsed,
    gaveUp: gaveUp,
  };
  console.log(data);
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Submission response was not ok.");
      }

      return response.json();
    })
    .then((data) => {
      // Handle the response data here (if needed)
      console.log("Response:", data);

      useGameStore.getState().setGlobalStats(data);
      usePersistentStore.getState().setSubmitted(true);

      // Re-fetch leaderboard in background
      mutate("/api/leaderboard/" + gridLevelDateKey);

      // Add results to the game store / persistent store
    });
  // .catch((error) => {
  //   // Handle any error that occurred during the fetch
  //   console.error("Error:", error);
  // });
}
