import { useState, useEffect } from "react";
import { shallow } from "zustand/shallow";
import HowToPlayButton from "./modals/HowToPlayButton";
import StatsDialog from "./modals/StatsDialog";
import CardsButton from "./buttons/CardsButton";
import AccountButton from "./buttons/AccountButton";
import usePersistentStore from "../lib/store/PersistentStore";
import useGameStore from "../lib/store/GameStore";
import { TwitterLink } from "./TwitterLink";
import { ClickAwayListener } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { gridLevelDateKey, gridLevelNumber } from "../lib/utils/gridLevels";

export function HeaderIcons() {
  const [history, guessesLeft] = usePersistentStore(
    (state) => [state.history, state.guessesLeft],
    shallow
  );

  const [user, isUserLoading] = useGameStore(
    (state) => [state.user, state.isUserLoading],
    shallow
  );

  const hasPlayed = Object.keys(history).length > 1 || guessesLeft == 0;
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const show = Object.keys(history).length > 1 || guessesLeft == 0;

  return user ? (
    <div className="flex items-center md:space-x-2">
      {/* <TwitterLink /> */}
      <NoSpoilersButton />
      <StatsDialog />
      <AccountButton />
    </div>
  ) : isClient && !hasPlayed ? (
    <div className="flex items-center md:space-x-2">
      <NoSpoilersButton />
      {/* <TwitterLink /> */}
      <HowToPlayButton modalTitle="How to Play PokeGrid" />
      <AccountButton />
    </div>
  ) : (
    <div className="flex items-center md:space-x-2">
      {/* <TwitterLink /> */}

      <NoSpoilersButton />
      <StatsDialog />
      {/* <HowToPlayButton modalTitle="How to Play PokeGrid" /> */}
      <AccountButton />
    </div>
  );
}

function NoSpoilersButton() {
  const [noSpoilers, toggleNoSpoilers] = useGameStore(
    (state) => [state.noSpoilers, state.toggleNoSpoilers],
    shallow
  );

  const [lastPlayed] = usePersistentStore(
    (state) => [state.lastPlayed],
    shallow
  );
  const [open, setOpen] = useState(
    lastPlayed != gridLevelDateKey && gridLevelNumber < 105
  );

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <button
      onClick={toggleNoSpoilers}
      className="-my-1 flex w-10 items-center justify-center py-2 font-bold uppercase text-black md:w-auto md:px-2"
    >
      {noSpoilers ? (
        <IconEyeInvisible className="h-6 w-6 sm:h-7 sm:w-7" />
      ) : (
        <IconEye className="h-6 w-6 sm:h-7 sm:w-7" />
      )}
    </button>
  );
}

function IconEye(props) {
  return (
    <svg
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
      <path d="M15 12 A3 3 0 0 1 12 15 A3 3 0 0 1 9 12 A3 3 0 0 1 15 12 z" />
    </svg>
  );
}

function IconEyeInvisible(props) {
  return (
    <svg
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M17.94 17.94A10.07 10.07 0 0112 20c-7 0-11-8-11-8a18.45 18.45 0 015.06-5.94M9.9 4.24A9.12 9.12 0 0112 4c7 0 11 8 11 8a18.5 18.5 0 01-2.16 3.19m-6.72-1.07a3 3 0 11-4.24-4.24M1 1l22 22" />
    </svg>
  );
}
