import { useState } from "react";
import { isMobile } from "react-device-detect";

import { handleShare } from "../../lib/utils/share";

function ShareButton() {
  const [isCopiedShown, setIsCopiedShown] = useState(false);

  const handleClick = () => {
    handleShare();

    if (!isMobile) {
      setIsCopiedShown(true);
      setTimeout(() => {
        setIsCopiedShown(false);
      }, 3000);
    }
  };

  return (
    <button
      className="relative mb-2 mr-2 inline-flex items-center rounded-lg  bg-red-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-200 dark:border-gray-700 dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      onClick={handleClick}
    >
      {isMobile ? (
        <svg
          aria-hidden="true"
          className="-ml-1 mr-2 h-5 w-6"
          viewBox="0 0 24 24"
          fill="white"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M13 14h-2a8.999 8.999 0 00-7.968 4.81A10.136 10.136 0 013 18C3 12.477 7.477 8 13 8V3l10 8-10 8v-5z" />
        </svg>
      ) : (
        <svg
          aria-hidden="true"
          className="-ml-1 mr-2 h-5 w-6"
          viewBox="0 0 1024 1024"
          fill="white"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32zM704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32zM382 896h-.2L232 746.2v-.2h150v150z" />
        </svg>
      )}

      <span className="hidden md:inline-block">
        {isMobile ? "Share" : "Copy"} Score
      </span>
      <span className="md:hidden">{isMobile ? "Share" : "Copy"}</span>
      <span
        className={`transition-opacity duration-500 ${
          isCopiedShown ? "opacity-100" : "opacity-0"
        } absolute left-1/2 top-full -translate-x-1/2 translate-y-1.5 text-xs text-red-700`}
      >
        COPIED!
      </span>
    </button>
  );
}

export default ShareButton;
