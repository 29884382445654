import { useEffect, useState } from "react";
import { IconUser } from "../icons/IconUser";
import useGameStore from "../../lib/store/GameStore";
import AccountMenu from "./AccountMenu";
import { shallow } from "zustand/shallow";

function AccountButton() {
  const [user, isUserLoading, setIsOpen] = useGameStore(
    (state) => [state.user, state.isUserLoading, state.setIsRegisterOpen],
    shallow
  );

  function openModal() {
    setIsOpen(true);
    // trackEvent("how_to_play_button");
    // trackSelect({
    //   contentType: "button",
    //   itemId: "how_to_play",
    // });
  }

  if (isUserLoading) return <div className="min-w-[4.25rem]"></div>;
  // if (isError) return "Error";

  return user ? (
    // <button onClick={logout} className="-my-2 w-10">
    //   <div className="relative inline-flex h-8 w-8 items-center justify-center overflow-hidden rounded-full bg-gray-100 dark:bg-gray-600 md:h-10 md:w-10">
    //     <span className="font-medium leading-none text-gray-700 dark:text-gray-300 md:text-lg ">
    //       {user.user.displayName.charAt(0).toUpperCase()}
    //     </span>
    //   </div>
    // </button>
    <AccountMenu />
  ) : (
    <div className="flex items-center justify-center pl-2">
      {/* <button
        aria-label="Sign in"
        onClick={openModal}
        className="-my-2 rounded-full bg-red-600 px-4 py-2 text-center text-xs font-medium text-white ring-offset-2 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 md:text-sm"
      >
        Sign In
      </button> */}
      <a
        aria-label="Sign in"
        href={`${process.env.NEXT_PUBLIC_API_BASE_URL}/account/login?utm_content=header`}
        className="-my-2 text-ellipsis whitespace-nowrap rounded-full bg-red-600 px-4 py-2 text-center text-xs font-medium text-white antialiased ring-offset-2 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 md:text-sm"
      >
        Sign In
      </a>
    </div>
  );
}

function IconCog(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
      />
    </svg>
  );
}

export default AccountButton;
